import { useState, useEffect } from "react";
import { StyledPreparadoresDePolimeroEmEmulsao } from "./style";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Modal, ModalCloseButton } from "../../Modal";

const images = [
  {
    id: 1,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701289980/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_com_Estrutura_em_Inox_Fechamento_de_Aluminio_com_Policarbonato_e_Tubula%C3%A7%C3%A3o_em_Inox_Sch10_1.0_us5kom.jpg",
    alt: "Skid com Estrutura em Inox, Fechamento de Aluminio com  Policarbonato e Tubulação em Inox Sch10",
    description:
      "Skid com Estrutura em Inox, Fechamento de Aluminio com  Policarbonato e Tubulação em Inox Sch10",
    group: "Componentes",
  },
  {
    id: 2,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701289979/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_com_Estrutura_em_Inox_Fechamento_de_Aluminio_com_Policarbonato_e_Tubula%C3%A7%C3%A3o_em_Inox_Sch10_1.1_hsdijc.jpg",
    alt: "Skid com Estrutura em Inox, Fechamento de Aluminio com  Policarbonato e Tubulação em Inox Sch10",
    description:
      "Skid com Estrutura em Inox, Fechamento de Aluminio com  Policarbonato e Tubulação em Inox Sch10",
    group: "Componentes",
  },
  {
    id: 3,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701289980/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_com_Estrutura_em_Inox_Fechamento_de_Aluminio_com_Policarbonato_e_Tubula%C3%A7%C3%A3o_em_Inox_Sch10_1.2_bmkiae.jpg",
    alt: "Skid com Estrutura em Inox, Fechamento de Aluminio com  Policarbonato e Tubulação em Inox Sch10",
    description:
      "Skid com Estrutura em Inox, Fechamento de Aluminio com  Policarbonato e Tubulação em Inox Sch10",
    group: "Componentes",
  },
  {
    id: 4,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701289979/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_com_Estrutura_em_Inox_Fechamento_de_Aluminio_com_Policarbonato_e_Tubula%C3%A7%C3%A3o_em_Inox_Sch10_2.0_rkggso.jpg",
    alt: "Skid com Estrutura em Inox, Fechamento de Aluminio com  Policarbonato e Tubulação em Inox Sch10",
    description:
      "Skid com Estrutura em Inox, Fechamento de Aluminio com  Policarbonato e Tubulação em Inox Sch10",
    group: "Componentes2",
  },
  {
    id: 5,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701289978/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_com_Estrutura_em_Inox_Fechamento_de_Aluminio_com_Policarbonato_e_Tubula%C3%A7%C3%A3o_em_Inox_Sch10_2.1_ixaxoe.jpg",
    alt: "Skid com Estrutura em Inox, Fechamento de Aluminio com  Policarbonato e Tubulação em Inox Sch10",
    description:
      "Skid com Estrutura em Inox, Fechamento de Aluminio com  Policarbonato e Tubulação em Inox Sch10",
    group: "Componentes2",
  },
  {
    id: 6,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701289979/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_com_Estrutura_em_Inox_Fechamento_de_Aluminio_com_Policarbonato_e_Tubula%C3%A7%C3%A3o_em_Inox_Sch10_2.2_qsy3jm.jpg",
    alt: "Skid com Estrutura em Inox, Fechamento de Aluminio com  Policarbonato e Tubulação em Inox Sch10",
    description:
      "Skid com Estrutura em Inox, Fechamento de Aluminio com  Policarbonato e Tubulação em Inox Sch10",
    group: "Componentes2",
  },
  {
    id: 7,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701289977/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_com_Estrutura_em_Inox_Fechamento_de_Alum%C3%ADnio_com_Policarbonato_e_Tubula%C3%A7%C3%A3o_em_PVC_SCH80_1.0_upm2oc.jpg",
    alt: "Skid com Estrutura em Inox, Fechamento de Alumínio com  Policarbonato e Tubulação em PVC SCH80",
    description:
      "Skid com Estrutura em Inox, Fechamento de Alumínio com  Policarbonato e Tubulação em PVC SCH80",
    group: "Componentes3",
  },
  {
    id: 8,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701289977/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_com_Estrutura_em_Inox_Fechamento_de_Alum%C3%ADnio_com_Policarbonato_e_Tubula%C3%A7%C3%A3o_em_PVC_SCH80_1.1_gryumt.jpg",
    alt: "Skid com Estrutura em Inox, Fechamento de Alumínio com  Policarbonato e Tubulação em PVC SCH80",
    description:
      "Skid com Estrutura em Inox, Fechamento de Alumínio com  Policarbonato e Tubulação em PVC SCH80",
    group: "Componentes3",
  },
  {
    id: 9,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701289978/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_com_Estrutura_em_Inox_Fechamento_de_Alum%C3%ADnio_com_Policarbonato_e_Tubula%C3%A7%C3%A3o_em_PVC_SCH80_1.2_m8bmhu.jpg",
    alt: "Skid com Estrutura em Inox, Fechamento de Alumínio com  Policarbonato e Tubulação em PVC SCH80",
    description:
      "Skid com Estrutura em Inox, Fechamento de Alumínio com  Policarbonato e Tubulação em PVC SCH80",
    group: "Componentes3",
  },
  {
    id: 10,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701289977/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_com_Estrutura_em_Inox_Fechamento_de_Aluminio_com_Policarbonato_e_Tubula%C3%A7%C3%A3o_em_PVC_SCH80_2.0_z1eizs.jpg",
    alt: "Skid com Estrutura em Inox, Fechamento de Aluminio com  Policarbonato e Tubulação em PVC SCH80",
    description:
      "Skid com Estrutura em Inox, Fechamento de Aluminio com  Policarbonato e Tubulação em PVC SCH80",
    group: "Componentes4",
  },
  {
    id: 11,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701289977/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_com_Estrutura_em_Inox_Fechamento_de_Aluminio_com_Policarbonato_e_Tubula%C3%A7%C3%A3o_em_PVC_SCH80_2.1_xqmlmx.jpg",
    alt: "Skid com Estrutura em Inox, Fechamento de Aluminio com  Policarbonato e Tubulação em PVC SCH80",
    description:
      "Skid com Estrutura em Inox, Fechamento de Aluminio com  Policarbonato e Tubulação em PVC SCH80",
    group: "Componentes4",
  },
  {
    id: 12,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701289977/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_com_Estrutura_em_Inox_Fechamento_de_Aluminio_com_Policarbonato_e_Tubula%C3%A7%C3%A3o_em_PVC_SCH80_2.2_snama2.jpg",
    alt: "Skid com Estrutura em Inox, Fechamento de Aluminio com  Policarbonato e Tubulação em PVC SCH80",
    description:
      "Skid com Estrutura em Inox, Fechamento de Aluminio com  Policarbonato e Tubulação em PVC SCH80",
    group: "Componentes4",
  },
  {
    id: 13,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701289968/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_todo_em_PP_Fechamento_de_Aluminio_com_Policarbonato_e_Tubula%C3%A7%C3%A3o_em_PVC_SCH80_1.0_rtwrai.jpg",
    alt: "Skid todo em PP, Fechamento de Aluminio com  Policarbonato e Tubulação em PVC SCH80",
    description:
      "Skid todo em PP, Fechamento de Aluminio com  Policarbonato e Tubulação em PVC SCH80",
    group: "Componentes5",
  },
  {
    id: 14,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701289968/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_todo_em_PP_Fechamento_de_Aluminio_com_Policarbonato_e_Tubula%C3%A7%C3%A3o_em_PVC_SCH80_1.1_maiimr.jpg",
    alt: "Skid todo em PP, Fechamento de Aluminio com  Policarbonato e Tubulação em PVC SCH80",
    description:
      "Skid todo em PP, Fechamento de Aluminio com  Policarbonato e Tubulação em PVC SCH80",
    group: "Componentes5",
  },
  {
    id: 15,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701289976/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_todo_em_PP_Fechamento_de_Aluminio_com_Policarbonato_e_Tubula%C3%A7%C3%A3o_em_PVC_SCH80_1.2_zhxwae.jpg",
    alt: "Skid todo em PP, Fechamento de Aluminio com  Policarbonato e Tubulação em PVC SCH80",
    description:
      "Skid todo em PP, Fechamento de Aluminio com  Policarbonato e Tubulação em PVC SCH80",
    group: "Componentes5",
  },
  {
    id: 16,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701289967/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_todo_em_PP_Fechamento_de_Aluminio_com_Policarbonato_e_Tubula%C3%A7%C3%A3o_em_PVC_SCH80_2.0_l6vkfa.jpg",
    alt: "Skid todo em PP, Fechamento de Aluminio com  Policarbonato e Tubulação em PVC SCH80",
    description:
      "Skid todo em PP, Fechamento de Aluminio com  Policarbonato e Tubulação em PVC SCH80",
    group: "Componentes6",
  },
  {
    id: 17,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701289968/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_todo_em_PP_Fechamento_de_Aluminio_com_Policarbonato_e_Tubula%C3%A7%C3%A3o_em_PVC_SCH80_2.1_oqlvhy.jpg",
    alt: "Skid todo em PP, Fechamento de Aluminio com  Policarbonato e Tubulação em PVC SCH80",
    description:
      "Skid todo em PP, Fechamento de Aluminio com  Policarbonato e Tubulação em PVC SCH80",
    group: "Componentes6",
  },
  {
    id: 18,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701289968/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_todo_em_PP_Fechamento_de_Aluminio_com_Policarbonato_e_Tubula%C3%A7%C3%A3o_em_PVC_SCH80_2.2_bzv7zz.jpg",
    alt: "Skid todo em PP, Fechamento de Aluminio com  Policarbonato e Tubulação em PVC SCH80",
    description:
      "Skid todo em PP, Fechamento de Aluminio com  Policarbonato e Tubulação em PVC SCH80",
    group: "Componentes6",
  },
  {
    id: 19,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701289967/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Sistema_de_Dosagem_de_Pol%C3%ADmero_1.0_wymfaq.jpg",
    alt: "Sistema de Dosagem de Polímero",
    description: "Sistema de Dosagem de Polímero",
    group: "Componentes7",
  },
  {
    id: 20,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701289967/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Sistema_de_Dosagem_de_Pol%C3%ADmero_1.1_nlx3dj.jpg",
    alt: "Sistema de Dosagem de Polímero",
    description: "Sistema de Dosagem de Polímero",
    group: "Componentes7",
  },
  {
    id: 21,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701289967/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Sistema_de_Dosagem_de_Pol%C3%ADmero_1.2_iv4vmb.jpg",
    alt: "Sistema de Dosagem de Polímero",
    description: "Sistema de Dosagem de Polímero",
    group: "Componentes7",
  },
  {
    id: 22,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701442700/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_todo_em_PP_para_Dosagem_de_Pol%C3%ADmero_com_2_Bombas_Helicoidais_1.0_tpnwpj.jpg",
    alt: "Skid todo em PP para Dosagem de Polímero com 2 Bombas Helicoidais",
    description:
      "Skid todo em PP para Dosagem de Polímero com 2 Bombas Helicoidais",
    group: "Componentes8",
  },
  {
    id: 23,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701442700/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_todo_em_PP_para_Dosagem_de_Pol%C3%ADmero_com_2_Bombas_Helicoidais_1.1_mscwzr.jpg",
    alt: "Skid todo em PP para Dosagem de Polímero com 2 Bombas Helicoidais",
    description:
      "Skid todo em PP para Dosagem de Polímero com 2 Bombas Helicoidais",
    group: "Componentes8",
  },
  {
    id: 24,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701442701/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_todo_em_PP_para_Dosagem_de_Pol%C3%ADmero_com_2_Bombas_Helicoidais_1.2_okqovn.jpg",
    alt: "Skid todo em PP para Dosagem de Polímero com 2 Bombas Helicoidais",
    description:
      "Skid todo em PP para Dosagem de Polímero com 2 Bombas Helicoidais",
    group: "Componentes8",
  },
  {
    id: 25,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701442701/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_todo_em_PP_para_Preparo_e_Dosagem_de_Pol%C3%ADmero_com_2_Bombas_Helicoidais_e_Bomba_de_%C3%81gua_1.0_uvohfp.jpg",
    alt: "Skid todo em PP para Preparo e Dosagem de Polímero com 2 Bombas Helicoidais e Bomba de Água",
    description:
      "Skid todo em PP para Preparo e Dosagem de Polímero com 2 Bombas Helicoidais e Bomba de Água",
    group: "Componentes9",
  },
  {
    id: 26,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701442701/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_todo_em_PP_para_Preparo_e_Dosagem_de_Pol%C3%ADmero_com_2_Bombas_Helicoidais_e_Bomba_de_%C3%81gua_1.1_nrmmkc.jpg",
    alt: "Skid todo em PP para Preparo e Dosagem de Polímero com 2 Bombas Helicoidais e Bomba de Água",
    description:
      "Skid todo em PP para Preparo e Dosagem de Polímero com 2 Bombas Helicoidais e Bomba de Água",
    group: "Componentes9",
  },
  {
    id: 27,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701442702/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_todo_em_PP_para_Preparo_e_Dosagem_de_Pol%C3%ADmero_com_2_Bombas_Helicoidais_e_Bomba_de_%C3%81gua_1.2_w6ywei.jpg",
    alt: "Skid todo em PP para Preparo e Dosagem de Polímero com 2 Bombas Helicoidais e Bomba de Água",
    description:
      "Skid todo em PP para Preparo e Dosagem de Polímero com 2 Bombas Helicoidais e Bomba de Água",
    group: "Componentes9",
  },
];

const idToGroup: Record<number, string> = {};
images.forEach((image) => {
  idToGroup[image.id] = image.group;
});

const PreparadoresDePolimeroEmEmulsao = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(1);

  const openModal = (id: number) => {
    setSelectedImageId(id);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const openPrevImage = () => {
    const currentGroup = idToGroup[selectedImageId];
    const groupImages = images.filter((image) => image.group === currentGroup);
    const currentIndex = groupImages.findIndex(
      (image) => image.id === selectedImageId
    );

    if (currentIndex !== -1) {
      const prevIndex =
        (currentIndex - 1 + groupImages.length) % groupImages.length;
      setSelectedImageId(groupImages[prevIndex].id);
    }
  };

  const openNextImage = () => {
    const currentGroup = idToGroup[selectedImageId];
    const groupImages = images.filter((image) => image.group === currentGroup);
    const currentIndex = groupImages.findIndex(
      (image) => image.id === selectedImageId
    );

    if (currentIndex !== -1) {
      const nextIndex = (currentIndex + 1) % groupImages.length;
      setSelectedImageId(groupImages[nextIndex].id);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        openPrevImage();
      } else if (event.key === "ArrowRight") {
        openNextImage();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <StyledPreparadoresDePolimeroEmEmulsao>
      <div className="containers">
        <h1 className="resp-mobile"> Preparadores de polímeros em Emulsão</h1>
        <div className="image-containers">
          {images
            .filter(
              (image) =>
                image.id === 1 ||
                image.id === 4 ||
                image.id === 7 ||
                image.id === 10 ||
                image.id === 13 ||
                image.id === 16 ||
                image.id === 19 ||
                image.id === 22 ||
                image.id === 25
            )
            .map((image) => (
              <div
                className="pair"
                key={image.id}
                onClick={() => openModal(image.id)}
              >
                <div
                  className="image"
                  onContextMenu={(e: React.MouseEvent) => e.preventDefault()}
                >
                  <img src={image.src} alt={image.alt} loading="lazy" />
                </div>
                <div className="description">
                  <span>{image.description}</span>
                </div>
              </div>
            ))}
        </div>
      </div>

      {modalIsOpen && (
        <Modal onClick={handleModalClick}>
          <ModalCloseButton onClick={closeModal}>&times;</ModalCloseButton>
          <div>
            <button onClick={openPrevImage}>
              {" "}
              <AiOutlineArrowLeft />{" "}
            </button>
            <img
              key={selectedImageId}
              src={images.find((image) => image.id === selectedImageId)!.src}
              alt={images.find((image) => image.id === selectedImageId)!.alt}
            />
            <button onClick={openNextImage}>
              {" "}
              <AiOutlineArrowRight />{" "}
            </button>
          </div>
        </Modal>
      )}
    </StyledPreparadoresDePolimeroEmEmulsao>
  );
};

export default PreparadoresDePolimeroEmEmulsao;
