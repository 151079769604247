import { useState, useEffect } from "react";
import { StyledTratamentoEControleDeAguaParaTorreDeResfriamento } from "./style";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Modal, ModalCloseButton } from "../../Modal";

const images = [
  {
    id: 1,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452514/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Esta%C3%A7%C3%A3o_de_An%C3%A1lise_de_Corpo_de_Prova_1.1_vm4mqx.jpg",
    alt: "Estação de Análise de Corpo de Prova",
    description: "Estação de Análise de Corpo de Prova",
    group: "Componentes",
  },
  {
    id: 2,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452514/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Esta%C3%A7%C3%A3o_de_An%C3%A1lise_de_Corpo_de_Prova_1.0_eyiiuj.jpg",
    alt: "Estação de Análise de Corpo de Prova",
    description: "Estação de Análise de Corpo de Prova",
    group: "Componentes",
  },
  {
    id: 3,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452513/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_de_Dosagem_em_PP_-_Customizado_-_Tubula%C3%A7%C3%A3o_PVC_SCH80_1.0_zafoo5.jpg",
    alt: "Skid de Dosagem em PP - Customizado - Tubulação PVC SCH80",
    description: "Skid de Dosagem em PP - Customizado - Tubulação PVC SCH80",
    group: "Componentes2",
  },
  {
    id: 4,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452513/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_de_Dosagem_em_PP_-_Customizado_-_Tubula%C3%A7%C3%A3o_PVC_SCH80_1.1_lzvdtg.jpg",
    alt: "Skid de Dosagem em PP - Customizado - Tubulação PVC SCH80",
    description: "Skid de Dosagem em PP - Customizado - Tubulação PVC SCH80",
    group: "Componentes2",
  },
  {
    id: 5,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452512/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_de_Dosagem_em_PP_-_Customizado_-_Tubula%C3%A7%C3%A3o_PVC_SCH80_1.2_nvyc7z.jpg",
    alt: "Skid de Dosagem em PP - Customizado - Tubulação PVC SCH80",
    description: "Skid de Dosagem em PP - Customizado - Tubulação PVC SCH80",
    group: "Componentes2",
  },
  {
    id: 6,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452511/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_para_Instala%C3%A7%C3%A3o_em_Parede_para_An%C3%A1lise_de_pH_Orp_Condutividade_e_Tubula%C3%A7%C3%A3o_PVC_SCH80_1.1_hjefqv.jpg",
    alt: "Skid para Instalação em Parede para Análise de pH, Orp, Condutividade e Tubulação PVC SCH80",
    description:
      "Skid para Instalação em Parede para Análise de pH, Orp, Condutividade e Tubulação PVC SCH80",
    group: "Componentes3",
  },
  {
    id: 7,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452511/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_para_Instala%C3%A7%C3%A3o_em_Parede_para_An%C3%A1lise_de_pH_Orp_Condutividade_e_Tubula%C3%A7%C3%A3o_PVC_SCH80_1.0_hyoyza.jpg",
    alt: "Skid para Instalação em Parede para Análise de pH, Orp, Condutividade e Tubulação PVC SCH80",
    description:
      "Skid para Instalação em Parede para Análise de pH, Orp, Condutividade e Tubulação PVC SCH80",
    group: "Componentes3",
  },
  {
    id: 8,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452510/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_Alojamento_de_3_Bombas_com_Acionamento_Simples_e_Tomadas_Embutidas_1.0_iiox7e.jpg",
    alt: "Skid todo em PP para Alojamento de 3 Bombas com Acionamento Simples e Tomadas Embutidas",
    description:
      "Skid todo em PP para Alojamento de 3 Bombas com Acionamento Simples e Tomadas Embutidas",
    group: "Componentes4",
  },
  {
    id: 9,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452510/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_Alojamento_de_3_Bombas_com_Acionamento_Simples_e_Tomadas_Embutidas_1.1_m81zaq.jpg",
    alt: "Skid todo em PP para Alojamento de 3 Bombas com Acionamento Simples e Tomadas Embutidas",
    description:
      "DSkid todo em PP para Alojamento de 3 Bombas com Acionamento Simples e Tomadas Embutidas",
    group: "Componentes4",
  },
  {
    id: 10,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452509/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_Alojamento_de_3_Bombas_com_Acionamento_Simples_e_Tomadas_Embutidas_1.2_nn47g2.jpg",
    alt: "Skid todo em PP para Alojamento de 3 Bombas com Acionamento Simples e Tomadas Embutidas",
    description:
      "Skid todo em PP para Alojamento de 3 Bombas com Acionamento Simples e Tomadas Embutidas",
    group: "Componentes4",
  },
  {
    id: 11,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452508/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_Alojamento_de_4_Bombas_An%C3%A1lise_pH_Orp_Condutividade_e_Acionamento_Local_e_Remoto_1.2_ppxwx7.jpg",
    alt: "Skid todo em PP para Alojamento de 4 Bombas, Análise pH, Orp, Condutividade e Acionamento Local e Remoto",
    description:
      "Skid todo em PP para Alojamento de 4 Bombas, Análise pH, Orp, Condutividade e Acionamento Local e Remoto",
    group: "Componentes5",
  },
  {
    id: 12,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452509/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_Alojamento_de_4_Bombas_An%C3%A1lise_pH_Orp_Condutividade_e_Acionamento_Local_e_Remoto_1.0_nmoghb.jpg",
    alt: "Skid todo em PP para Alojamento de 4 Bombas, Análise pH, Orp, Condutividade e Acionamento Local e Remoto",
    description:
      "Skid todo em PP para Alojamento de 4 Bombas, Análise pH, Orp, Condutividade e Acionamento Local e Remoto",
    group: "Componentes5",
  },
  {
    id: 13,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452508/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_Alojamento_de_4_Bombas_An%C3%A1lise_pH_Orp_Condutividade_e_Acionamento_Local_e_Remoto_1.1_gp4d1n.jpg",
    alt: "Skid todo em PP para Alojamento de 4 Bombas, Análise pH, Orp, Condutividade e Acionamento Local e Remoto",
    description:
      "Skid todo em PP para Alojamento de 4 Bombas, Análise pH, Orp, Condutividade e Acionamento Local e Remoto",
    group: "Componentes5",
  },
  {
    id: 14,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452507/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_Alojamento_de_5_Bombas_com_Acionamento_Simples_e_Tomadas_Embutidas_1.1_glsvmk.jpg",
    alt: "Skid todo em PP para Alojamento de 5 Bombas com Acionamento Simples e Tomadas Embutidas",
    description:
      "Skid todo em PP para Alojamento de 5 Bombas com Acionamento Simples e Tomadas Embutidas",
    group: "Componentes6",
  },
  {
    id: 15,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452507/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_Alojamento_de_5_Bombas_com_Acionamento_Simples_e_Tomadas_Embutidas_1.0_hq6mve.jpg",
    alt: "Skid todo em PP para Alojamento de 5 Bombas com Acionamento Simples e Tomadas Embutidas",
    description:
      "Skid todo em PP para Alojamento de 5 Bombas com Acionamento Simples e Tomadas Embutidas",
    group: "Componentes6",
  },
  {
    id: 16,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452506/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_Alojamento_de_5_Bombas_com_Acionamento_Simples_e_Tomadas_Embutidas_1.2_gxuuod.jpg",
    alt: "Skid todo em PP para Alojamento de 5 Bombas com Acionamento Simples e Tomadas Embutidas",
    description:
      "Skid todo em PP para Alojamento de 5 Bombas com Acionamento Simples e Tomadas Embutidas",
    group: "Componentes6",
  },
  {
    id: 17,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452505/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_An%C3%A1lise_de_pH_Orp_e_Corros%C3%A3o_1.1_thacuo.jpg",
    alt: "Skid todo em PP para Análise de pH, Orp e Corrosão",
    description: "Skid todo em PP para Análise de pH, Orp e Corrosão",
    group: "Componentes7",
  },
  {
    id: 18,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452506/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_An%C3%A1lise_de_pH_Orp_e_Corros%C3%A3o_1.0_kyorjm.jpg",
    alt: "Skid todo em PP para Análise de pH, Orp e Corrosão",
    description: "Skid todo em PP para Análise de pH, Orp e Corrosão",
    group: "Componentes7",
  },
  {
    id: 19,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452505/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_An%C3%A1lise_de_pH_Orp_Cloro_Livre_Alojamento_para_2_Bombas_de_Dosagem_e_Acionamento_Local_e_Remoto_1.1_esvfsb.jpg",
    alt: "Skid todo em PP para Análise de pH, Orp, Cloro Livre, Alojamento para 2 Bombas de Dosagem e Acionamento Local e Remoto",
    description:
      "Skid todo em PP para Análise de pH, Orp, Cloro Livre, Alojamento para 2 Bombas de Dosagem e Acionamento Local e Remoto",
    group: "Componentes8",
  },
  {
    id: 20,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452505/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_An%C3%A1lise_de_pH_Orp_Cloro_Livre_Alojamento_para_2_Bombas_de_Dosagem_e_Acionamento_Local_e_Remoto_1.0_xzwwxk.jpg",
    alt: "Skid todo em PP para Análise de pH, Orp, Cloro Livre, Alojamento para 2 Bombas de Dosagem e Acionamento Local e Remoto",
    description:
      "Skid todo em PP para Análise de pH, Orp, Cloro Livre, Alojamento para 2 Bombas de Dosagem e Acionamento Local e Remoto",
    group: "Componentes8",
  },
  {
    id: 21,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452504/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_An%C3%A1lise_de_pH_Orp_Cloro_Livre_Alojamento_para_2_Bombas_de_Dosagem_e_Acionamento_Local_e_Remoto_1.2_trc5mx.jpg",
    alt: "Skid todo em PP para Análise de pH, Orp, Cloro Livre, Alojamento para 2 Bombas de Dosagem e Acionamento Local e Remoto",
    description:
      "Skid todo em PP para Análise de pH, Orp, Cloro Livre, Alojamento para 2 Bombas de Dosagem e Acionamento Local e Remoto",
    group: "Componentes8",
  },
  {
    id: 22,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452503/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_An%C3%A1lise_de_pH_Orp_Condutividade_e_Corros%C3%A3o_1.2_t8jzr3.jpg",
    alt: "Skid todo em PP para Análise de pH, Orp, Condutividade e Corrosão",
    description:
      "Skid todo em PP para Análise de pH, Orp, Condutividade e Corrosão",
    group: "Componentes9",
  },
  {
    id: 23,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452504/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_An%C3%A1lise_de_pH_Orp_Condutividade_e_Corros%C3%A3o_1.0_fdzdmj.jpg",
    alt: "Skid todo em PP para Análise de pH, Orp, Condutividade e Corrosão",
    description:
      "Skid todo em PP para Análise de pH, Orp, Condutividade e Corrosão",
    group: "Componentes9",
  },
  {
    id: 24,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452503/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_An%C3%A1lise_de_pH_Orp_Condutividade_e_Corros%C3%A3o_1.1_wwutgw.jpg",
    alt: "Skid todo em PP para Análise de pH, Orp, Condutividade e Corrosão",
    description:
      "Skid todo em PP para Análise de pH, Orp, Condutividade e Corrosão",
    group: "Componentes9",
  },
  {
    id: 25,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452502/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_An%C3%A1lise_de_pH_Orp_Condutividade_e_Corros%C3%A3o_At%C3%A9_2_Torres_de_Resfriamento_1.2_ft0ai9.jpg",
    alt: "Skid todo em PP para Análise de pH, Orp, Condutividade e Corrosão, Até 2 Torres de Resfriamento",
    description:
      "Skid todo em PP para Análise de pH, Orp, Condutividade e Corrosão, Até 2 Torres de Resfriamento",
    group: "Componentes10",
  },
  {
    id: 26,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452502/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_An%C3%A1lise_de_pH_Orp_Condutividade_e_Corros%C3%A3o_At%C3%A9_2_Torres_de_Resfriamento_1.1_dyljiz.jpg",
    alt: "Skid todo em PP para Análise de pH, Orp, Condutividade e Corrosão, Até 2 Torres de Resfriamento",
    description:
      "Skid todo em PP para Análise de pH, Orp, Condutividade e Corrosão, Até 2 Torres de Resfriamento",
    group: "Componentes10",
  },
  {
    id: 27,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452503/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_An%C3%A1lise_de_pH_Orp_Condutividade_e_Corros%C3%A3o_At%C3%A9_2_Torres_de_Resfriamento_1.0_ifmzpv.jpg",
    alt: "Skid todo em PP para Análise de pH, Orp, Condutividade e Corrosão, Até 2 Torres de Resfriamento",
    description:
      "Skid todo em PP para Análise de pH, Orp, Condutividade e Corrosão, Até 2 Torres de Resfriamento",
    group: "Componentes10",
  },
  {
    id: 28,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452502/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_An%C3%A1lise_de_pH_Orp_Condutividade_Corros%C3%A3o_e_Alojamento_para_4_Bombas_Dosadoras_1.2_s6mhe0.jpg",
    alt: "Skid todo em PP para Análise de pH, Orp, Condutividade, Corrosão e Alojamento para 4 Bombas Dosadoras",
    description:
      "Skid todo em PP para Análise de pH, Orp, Condutividade, Corrosão e Alojamento para 4 Bombas Dosadoras",
    group: "Componentes11",
  },
  {
    id: 29,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452502/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_An%C3%A1lise_de_pH_Orp_Condutividade_Corros%C3%A3o_e_Alojamento_para_4_Bombas_Dosadoras_1.0_i8ryf0.jpg",
    alt: "Skid todo em PP para Análise de pH, Orp, Condutividade, Corrosão e Alojamento para 4 Bombas Dosadoras",
    description:
      "Skid todo em PP para Análise de pH, Orp, Condutividade, Corrosão e Alojamento para 4 Bombas Dosadoras",
    group: "Componentes11",
  },
  {
    id: 30,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701452502/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_An%C3%A1lise_de_pH_Orp_Condutividade_Corros%C3%A3o_e_Alojamento_para_4_Bombas_Dosadoras_1.1_mvv3tn.jpg",
    alt: "Skid todo em PP para Análise de pH, Orp, Condutividade, Corrosão e Alojamento para 4 Bombas Dosadoras",
    description:
      "Skid todo em PP para Análise de pH, Orp, Condutividade, Corrosão e Alojamento para 4 Bombas Dosadoras",
    group: "Componentes11",
  },
];

const idToGroup: Record<number, string> = {};
images.forEach((image) => {
  idToGroup[image.id] = image.group;
});

const TratamentoEControleDeAguaParaTorreDeResfriamento = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(1);

  const openModal = (id: number) => {
    setSelectedImageId(id);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const openPrevImage = () => {
    const currentGroup = idToGroup[selectedImageId];
    const groupImages = images.filter((image) => image.group === currentGroup);
    const currentIndex = groupImages.findIndex(
      (image) => image.id === selectedImageId
    );

    if (currentIndex !== -1) {
      const prevIndex =
        (currentIndex - 1 + groupImages.length) % groupImages.length;
      setSelectedImageId(groupImages[prevIndex].id);
    }
  };

  const openNextImage = () => {
    const currentGroup = idToGroup[selectedImageId];
    const groupImages = images.filter((image) => image.group === currentGroup);
    const currentIndex = groupImages.findIndex(
      (image) => image.id === selectedImageId
    );

    if (currentIndex !== -1) {
      const nextIndex = (currentIndex + 1) % groupImages.length;
      setSelectedImageId(groupImages[nextIndex].id);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        openPrevImage();
      } else if (event.key === "ArrowRight") {
        openNextImage();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <StyledTratamentoEControleDeAguaParaTorreDeResfriamento>
      <div className="containers">
        <h1 className="resp-mobile">
          {" "}
          Tratamento e controle de água para torre de Resfriamento
        </h1>
        <div className="image-containers">
          {images
            .filter(
              (image) =>
                image.id === 1 ||
                image.id === 3 ||
                image.id === 6 ||
                image.id === 8 ||
                image.id === 11 ||
                image.id === 14 ||
                image.id === 17 ||
                image.id === 19 ||
                image.id === 22 ||
                image.id === 25 ||
                image.id === 28
            )
            .map((image) => (
              <div
                className="pair"
                key={image.id}
                onClick={() => openModal(image.id)}
              >
                <div
                  className="image"
                  onContextMenu={(e: React.MouseEvent) => e.preventDefault()}
                >
                  <img src={image.src} alt={image.alt} loading="lazy" />
                </div>
                <div className="description">
                  <span>{image.description}</span>
                </div>
              </div>
            ))}
        </div>
      </div>

      {modalIsOpen && (
        <Modal onClick={handleModalClick}>
          <ModalCloseButton onClick={closeModal}>&times;</ModalCloseButton>
          <div>
            <button onClick={openPrevImage}>
              {" "}
              <AiOutlineArrowLeft />{" "}
            </button>
            <img
              key={selectedImageId}
              src={images.find((image) => image.id === selectedImageId)!.src}
              alt={images.find((image) => image.id === selectedImageId)!.alt}
            />
            <button onClick={openNextImage}>
              {" "}
              <AiOutlineArrowRight />{" "}
            </button>
          </div>
        </Modal>
      )}
    </StyledTratamentoEControleDeAguaParaTorreDeResfriamento>
  );
};

export default TratamentoEControleDeAguaParaTorreDeResfriamento;
