import styled from 'styled-components';

export const Container = styled.div`
  width: 97%;
  margin: 0 auto;
  height: 100%;

  @media (min-width: 700px) {
    width: 90%;
  }
`;