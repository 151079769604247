import React from 'react';
import { Header } from './components/Header';
import { Container } from './container';
import WhatsAppButton from './components/WhatsappButton';
import { ScrollToTopButton } from './components/ScrollToTop';
import { Footer } from './components/Footer';
import { RoutesComponents } from './routes/routes';

export const App = () => {
  return (
    <>
      <Header />
      <RoutesComponents />
      <WhatsAppButton />
      <ScrollToTopButton />
      <Footer />
    </>
  );
};
