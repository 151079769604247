import { useState, useEffect } from "react";
import { StyledTratamentoEControleParaAguaDeCaldeira } from "./style";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Modal, ModalCloseButton } from "../../Modal";

const images = [
  {
    id: 1,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802659/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_em_Inox_com_Fechamento_de_Alum%C3%ADnio_e_Policarbonato_para_Dosagem_de_Produto_em_Linha_de_Alta_Press%C3%A3o_Tubula%C3%A7%C3%A3o_em_Inox_OD_1.0_okvvi8.jpg",
    alt: "Skid em Inox com Fechamento de Alumínio e Policarbonato para Dosagem de Produto em Linha de Alta Pressão, Tubulação em Inox OD",
    description:
      "Skid em Inox com Fechamento de Alumínio e Policarbonato para Dosagem de Produto em Linha de Alta Pressão, Tubulação em Inox OD",
    group: "Componentes",
  },
  {
    id: 2,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802658/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_em_Inox_com_Fechamento_de_Alum%C3%ADnio_e_Policarbonato_para_Dosagem_de_Produto_em_Linha_de_Alta_Press%C3%A3o_Tubula%C3%A7%C3%A3o_em_Inox_OD_1.1_x27hnt.jpg",
    alt: "Skid em Inox com Fechamento de Alumínio e Policarbonato para Dosagem de Produto em Linha de Alta Pressão, Tubulação em Inox OD",
    description:
      "Skid em Inox com Fechamento de Alumínio e Policarbonato para Dosagem de Produto em Linha de Alta Pressão, Tubulação em Inox OD",
    group: "Componentes",
  },
  {
    id: 3,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802658/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_em_Inox_com_Fechamento_de_Alum%C3%ADnio_e_Policarbonato_para_Dosagem_de_Produto_em_Linha_de_Alta_Press%C3%A3o_Tubula%C3%A7%C3%A3o_em_Inox_OD_1.2_haechj.jpg",
    alt: "Skid em Inox com Fechamento de Alumínio e Policarbonato para Dosagem de Produto em Linha de Alta Pressão, Tubulação em Inox OD",
    description:
      "Skid em Inox com Fechamento de Alumínio e Policarbonato para Dosagem de Produto em Linha de Alta Pressão, Tubulação em Inox OD",
    group: "Componentes",
  },
  {
    id: 4,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802622/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_todo_em_PP_para_Alojamento_de_3_Bombas_Acionamento_Local_e_Remoto_Tomadas_Embutidas_e_Tubula%C3%A7%C3%B5es_PVC_SCH80_1.1_cor6z0.jpg",
    alt: "Skid todo em PP para Alojamento de 3 Bombas, Acionamento Local e Remoto, Tomadas Embutidas e Tubulações PVC SCH80",
    description:
      "Skid todo em PP para Alojamento de 3 Bombas, Acionamento Local e Remoto, Tomadas Embutidas e Tubulações PVC SCH80",
    group: "Componentes2",
  },
  {
    id: 5,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802623/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_todo_em_PP_para_Alojamento_de_3_Bombas_Acionamento_Local_e_Remoto_Tomadas_Embutidas_e_Tubula%C3%A7%C3%B5es_PVC_SCH80_1.0_wqt6ve.jpg",
    alt: "Skid todo em PP para Alojamento de 3 Bombas, Acionamento Local e Remoto, Tomadas Embutidas e Tubulações PVC SCH80",
    description:
      "Skid todo em PP para Alojamento de 3 Bombas, Acionamento Local e Remoto, Tomadas Embutidas e Tubulações PVC SCH80",
    group: "Componentes2",
  },
  {
    id: 6,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802645/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_todo_em_PP_para_Alojamento_de_3_Bombas_Acionamento_Local_e_Remoto_Tomadas_Embutidas_e_Tubula%C3%A7%C3%B5es_PVC_SCH80_1.2_wxcrvy.jpg",
    alt: "Skid todo em PP para Alojamento de 3 Bombas, Acionamento Local e Remoto, Tomadas Embutidas e Tubulações PVC SCH80",
    description:
      "Skid todo em PP para Alojamento de 3 Bombas, Acionamento Local e Remoto, Tomadas Embutidas e Tubulações PVC SCH80",
    group: "Componentes2",
  },
  {
    id: 7,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802622/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_todo_em_PP_para_Alojamento_de_5_Bombas_com_Divis%C3%B3rias_Conten%C3%A7%C3%A3o_Acionamento_Local_e_Remoto_e_Tomadas_Embutidas_1.1_yu9kru.jpg",
    alt: "Skid todo em PP para Alojamento de 5 Bombas com Divisórias, Contenção, Acionamento Local e Remoto e Tomadas Embutidas",
    description:
      "Skid todo em PP para Alojamento de 5 Bombas com Divisórias, Contenção, Acionamento Local e Remoto e Tomadas Embutidas",
    group: "Componentes3",
  },
  {
    id: 8,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802622/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_todo_em_PP_para_Alojamento_de_5_Bombas_com_Divis%C3%B3rias_Conten%C3%A7%C3%A3o_Acionamento_Local_e_Remoto_e_Tomadas_Embutidas_1.0_ik7veh.jpg",
    alt: "Skid todo em PP para Alojamento de 5 Bombas com Divisórias, Contenção, Acionamento Local e Remoto e Tomadas Embutidas",
    description:
      "Skid todo em PP para Alojamento de 5 Bombas com Divisórias, Contenção, Acionamento Local e Remoto e Tomadas Embutidas",
    group: "Componentes3",
  },
  {
    id: 9,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802622/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_todo_em_PP_para_Alojamento_de_5_Bombas_com_Divis%C3%B3rias_Conten%C3%A7%C3%A3o_Acionamento_Local_e_Remoto_e_Tomadas_Embutidas_1.2_xddsip.jpg",
    alt: "Skid todo em PP para Alojamento de 5 Bombas com Divisórias, Contenção, Acionamento Local e Remoto e Tomadas Embutidas",
    description:
      "Skid todo em PP para Alojamento de 5 Bombas com Divisórias, Contenção, Acionamento Local e Remoto e Tomadas Embutidas",
    group: "Componentes3",
  },
  {
    id: 10,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802622/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_Aberto_em_Inox_para_Dosagem_de_Produto_em_Linha_de_Alta_Press%C3%A3o_Tubula%C3%A7%C3%A3o_em_Inox_OD_1.0_ssivci.jpg",
    alt: "Skid Aberto em Inox para Dosagem de Produto em Linha de Alta Pressão, Tubulação em Inox OD",
    description:
      "Skid Aberto em Inox para Dosagem de Produto em Linha de Alta Pressão, Tubulação em Inox OD",
    group: "Componentes4",
  },
  {
    id: 11,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802621/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_Aberto_em_Inox_para_Dosagem_de_Produto_em_Linha_de_Alta_Press%C3%A3o_Tubula%C3%A7%C3%A3o_em_Inox_OD_1.1_e9mn2e.jpg",
    alt: "Skid Aberto em Inox para Dosagem de Produto em Linha de Alta Pressão, Tubulação em Inox OD",
    description:
      "Skid Aberto em Inox para Dosagem de Produto em Linha de Alta Pressão, Tubulação em Inox OD",
    group: "Componentes4",
  },
  {
    id: 12,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802621/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_Aberto_em_Inox_para_Dosagem_de_Produto_em_Linha_de_Alta_Press%C3%A3o_Tubula%C3%A7%C3%A3o_em_Inox_OD_1.2_htpdfk.jpg",
    alt: "Skid Aberto em Inox para Dosagem de Produto em Linha de Alta Pressão, Tubulação em Inox OD",
    description:
      "Skid Aberto em Inox para Dosagem de Produto em Linha de Alta Pressão, Tubulação em Inox OD",
    group: "Componentes4",
  },
  {
    id: 14,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802621/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/SKIDEM_3_tw7rju.jpg",
    alt: "Skid em Inox com Fechamento de Alumínio e Policarbonato para Dosagem de Produto em Linha de Alta Pressão, Tubulação em Inox OD, Automatização via CLP, Sistema de Proteção Contra Temperatura e Resfriamento de Amostra",
    description:
      "Skid em Inox com Fechamento de Alumínio e Policarbonato para Dosagem de Produto em Linha de Alta Pressão, Tubulação em Inox OD, Automatização via CLP, Sistema de Proteção Contra Temperatura e Resfriamento de Amostra",
    group: "Componentes5",
  },
  {
    id: 15,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802621/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/SKIDEM_2_fqm8l4.jpg",
    alt: "Skid em Inox com Fechamento de Alumínio e Policarbonato para Dosagem de Produto em Linha de Alta Pressão, Tubulação em Inox OD, Automatização via CLP, Sistema de Proteção Contra Temperatura e Resfriamento de Amostra",
    description:
      "Skid em Inox com Fechamento de Alumínio e Policarbonato para Dosagem de Produto em Linha de Alta Pressão, Tubulação em Inox OD, Automatização via CLP, Sistema de Proteção Contra Temperatura e Resfriamento de Amostra",
    group: "Componentes5",
  },
  {
    id: 16,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802658/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_todo_em_PP_para_Alojamento_de_3_Bombas_com_Divis%C3%B3rias_Conten%C3%A7%C3%A3o_Acionamento_Local_e_Remoto_e_Tomadas_Embutidas_1.1_eu0w4i.jpg",
    alt: "Skid todo em PP para Alojamento de 3 Bombas com Divisórias, Contenção, Acionamento Local e Remoto e Tomadas Embutidas",
    description:
      "Skid todo em PP para Alojamento de 3 Bombas com Divisórias, Contenção, Acionamento Local e Remoto e Tomadas Embutidas",
    group: "Componentes6",
  },
  {
    id: 17,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802658/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_todo_em_PP_para_Alojamento_de_3_Bombas_com_Divis%C3%B3rias_Conten%C3%A7%C3%A3o_Acionamento_Local_e_Remoto_e_Tomadas_Embutidas_1.0_xzvxyk.jpg",
    alt: "Skid todo em PP para Alojamento de 3 Bombas com Divisórias, Contenção, Acionamento Local e Remoto e Tomadas Embutidas",
    description:
      "Skid todo em PP para Alojamento de 3 Bombas com Divisórias, Contenção, Acionamento Local e Remoto e Tomadas Embutidas",
    group: "Componentes6",
  },
  {
    id: 18,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802658/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_todo_em_PP_para_Alojamento_de_3_Bombas_com_Divis%C3%B3rias_Conten%C3%A7%C3%A3o_Acionamento_Local_e_Remoto_e_Tomadas_Embutidas_1.2_bz90z8.jpg",
    alt: "Skid todo em PP para Alojamento de 3 Bombas com Divisórias, Contenção, Acionamento Local e Remoto e Tomadas Embutidas",
    description:
      "Skid todo em PP para Alojamento de 3 Bombas com Divisórias, Contenção, Acionamento Local e Remoto e Tomadas Embutidas",
    group: "Componentes6",
  },
  {
    id: 19,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802621/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_todo_em_PP_para_Alojamento_de_4_Bombas_An%C3%A1lise_pH_Orp_Condutividade_Acionamento_Local_e_Remoto_e_Sistema_de_Prote%C3%A7%C3%A3o_Contra_Temperatura_e_Resfriamento_de_Amostra_1.1_h5idn5.jpg",
    alt: "Skid todo em PP para Alojamento de 4 Bombas, Análise pH, Orp, Condutividade, Acionamento Local e Remoto e Sistema de Proteção Contra Temperatura e Resfriamento de Amostra",
    description:
      "Skid todo em PP para Alojamento de 4 Bombas, Análise pH, Orp, Condutividade, Acionamento Local e Remoto e Sistema de Proteção Contra Temperatura e Resfriamento de Amostra",
    group: "Componentes7",
  },
  {
    id: 20,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802620/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_todo_em_PP_para_Alojamento_de_4_Bombas_An%C3%A1lise_pH_Orp_Condutividade_Acionamento_Local_e_Remoto_e_Sistema_de_Prote%C3%A7%C3%A3o_Contra_Temperatura_e_Resfriamento_de_Amostra_1.0_pl1qhl.jpg",
    alt: "Skid todo em PP para Alojamento de 4 Bombas, Análise pH, Orp, Condutividade, Acionamento Local e Remoto e Sistema de Proteção Contra Temperatura e Resfriamento de Amostra",
    description:
      "Skid todo em PP para Alojamento de 4 Bombas, Análise pH, Orp, Condutividade, Acionamento Local e Remoto e Sistema de Proteção Contra Temperatura e Resfriamento de Amostra",
    group: "Componentes7",
  },
  {
    id: 21,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802620/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_todo_em_PP_para_Alojamento_de_4_Bombas_An%C3%A1lise_pH_Orp_Condutividade_Acionamento_Local_e_Remoto_e_Sistema_de_Prote%C3%A7%C3%A3o_Contra_Temperatura_e_Resfriamento_de_Amostra_1.2_noo6ts.jpg",
    alt: "Skid todo em PP para Alojamento de 4 Bombas, Análise pH, Orp, Condutividade, Acionamento Local e Remoto e Sistema de Proteção Contra Temperatura e Resfriamento de Amostra",
    description:
      "Skid todo em PP para Alojamento de 4 Bombas, Análise pH, Orp, Condutividade, Acionamento Local e Remoto e Sistema de Proteção Contra Temperatura e Resfriamento de Amostra",
    group: "Componentes7",
  },
  {
    id: 22,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802620/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_todo_em_PP_para_An%C3%A1lise_de_pH_Orp_Condutividade_Automatiza%C3%A7%C3%A3o_via_CLP_e_Tubula%C3%A7%C3%A3o_em_PVC_SCH80_1.1_lkbbno.jpg",
    alt: "Skid todo em PP para Análise de pH, Orp, Condutividade, Automatização via CLP e Tubulação em PVC SCH80",
    description:
      "Skid todo em PP para Análise de pH, Orp, Condutividade, Automatização via CLP e Tubulação em PVC SCH80",
    group: "Componentes8",
  },
  {
    id: 23,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802619/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_todo_em_PP_para_An%C3%A1lise_de_pH_Orp_Condutividade_Automatiza%C3%A7%C3%A3o_via_CLP_e_Tubula%C3%A7%C3%A3o_em_PVC_SCH80_1.0_efkb5r.jpg",
    alt: "Skid todo em PP para Alojamento de 4 Bombas, Análise pH, Orp, Condutividade, Acionamento Local e Remoto e Sistema de Proteção Contra Temperatura e Resfriamento de Amostra",
    description:
      "Skid todo em PP para Alojamento de 4 Bombas, Análise pH, Orp, Condutividade, Acionamento Local e Remoto e Sistema de Proteção Contra Temperatura e Resfriamento de Amostra",
    group: "Componentes8",
  },
  {
    id: 24,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802620/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_todo_em_PP_para_An%C3%A1lise_de_pH_Orp_Condutividade_Automatiza%C3%A7%C3%A3o_via_CLP_e_Tubula%C3%A7%C3%A3o_em_PVC_SCH80_1.2_ezvi4x.jpg",
    alt: "Skid todo em PP para Alojamento de 4 Bombas, Análise pH, Orp, Condutividade, Acionamento Local e Remoto e Sistema de Proteção Contra Temperatura e Resfriamento de Amostra",
    description:
      "Skid todo em PP para Alojamento de 4 Bombas, Análise pH, Orp, Condutividade, Acionamento Local e Remoto e Sistema de Proteção Contra Temperatura e Resfriamento de Amostra",
    group: "Componentes8",
  },
  {
    id: 25,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802619/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_todo_em_PP_para_An%C3%A1lise_de_pH_Orp_Sistema_de_Prote%C3%A7%C3%A3o_Contra_Alta_Temperatura_e_Resfriamento_de_Amostra_1.2_h1gglt.jpg",
    alt: "Skid todo em PP para Análise de pH, Orp, Sistema de Proteção Contra Alta Temperatura e Resfriamento de Amostra",
    description:
      "Skid todo em PP para Análise de pH, Orp, Sistema de Proteção Contra Alta Temperatura e Resfriamento de Amostra",
    group: "Componentes9",
  },
  {
    id: 26,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802619/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_todo_em_PP_para_An%C3%A1lise_de_pH_Orp_Sistema_de_Prote%C3%A7%C3%A3o_Contra_Alta_Temperatura_e_Resfriamento_de_Amostra_1.0_hvkmhi.jpg",
    alt: "Skid todo em PP para Análise de pH, Orp, Sistema de Proteção Contra Alta Temperatura e Resfriamento de Amostra",
    description:
      "Skid todo em PP para Análise de pH, Orp, Sistema de Proteção Contra Alta Temperatura e Resfriamento de Amostra",
    group: "Componentes9",
  },
  {
    id: 27,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802619/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_todo_em_PP_para_An%C3%A1lise_de_pH_Orp_Sistema_de_Prote%C3%A7%C3%A3o_Contra_Alta_Temperatura_e_Resfriamento_de_Amostra_1.1_usaops.jpg",
    alt: "Skid todo em PP para Análise de pH, Orp, Sistema de Proteção Contra Alta Temperatura e Resfriamento de Amostra",
    description:
      "Skid todo em PP para Análise de pH, Orp, Sistema de Proteção Contra Alta Temperatura e Resfriamento de Amostra",
    group: "Componentes9",
  },
  {
    id: 28,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802619/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_todo_em_PP_para_Dosagem_de_Produto_com_Sistema_de_Dilui%C3%A7%C3%A3o_Automatiza%C3%A7%C3%A3o_via_CLP_e_Tubula%C3%A7%C3%A3o_em_PVC_SCH80_1.1_z7hlxh.jpg",
    alt: "Skid todo em PP para Dosagem de Produto com Sistema de Diluição, Automatização via CLP e Tubulação em PVC SCH80",
    description:
      "Skid todo em PP para Dosagem de Produto com Sistema de Diluição, Automatização via CLP e Tubulação em PVC SCH80",
    group: "Componentes10",
  },
  {
    id: 29,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802619/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_todo_em_PP_para_Dosagem_de_Produto_com_Sistema_de_Dilui%C3%A7%C3%A3o_Automatiza%C3%A7%C3%A3o_via_CLP_e_Tubula%C3%A7%C3%A3o_em_PVC_SCH80_1.0_zgyryk.jpg",
    alt: "Skid todo em PP para Dosagem de Produto com Sistema de Diluição, Automatização via CLP e Tubulação em PVC SCH80",
    description:
      "Skid todo em PP para Dosagem de Produto com Sistema de Diluição, Automatização via CLP e Tubulação em PVC SCH80",
    group: "Componentes10",
  },
  {
    id: 30,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802619/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_todo_em_PP_para_Dosagem_de_Produto_com_Sistema_de_Dilui%C3%A7%C3%A3o_Automatiza%C3%A7%C3%A3o_via_CLP_e_Tubula%C3%A7%C3%A3o_em_PVC_SCH80_1.2_xyb87v.jpg",
    alt: "Skid todo em PP para Dosagem de Produto com Sistema de Diluição, Automatização via CLP e Tubulação em PVC SCH80",
    description:
      "Skid todo em PP para Dosagem de Produto com Sistema de Diluição, Automatização via CLP e Tubulação em PVC SCH80",
    group: "Componentes10",
  },
  {
    id: 13,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701802620/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/SKIDEM_1_nkjrd5.jpg",
    alt: "Skid em Inox com Fechamento de Alumínio e Policarbonato para Dosagem de Produto em Linha de Alta Pressão, Tubulação em Inox OD, Automatização via CLP, Sistema de Proteção Contra Temperatura e Resfriamento de Amostra",
    description:
      "Skid em Inox com Fechamento de Alumínio e Policarbonato para Dosagem de Produto em Linha de Alta Pressão, Tubulação em Inox OD, Automatização via CLP, Sistema de Proteção Contra Temperatura e Resfriamento de Amostra",
    group: "Componentes5",
  },
];

const idToGroup: Record<number, string> = {};
images.forEach((image) => {
  idToGroup[image.id] = image.group;
});

const TratamentoEControleParaAguaDeCaldeira = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(1);

  const openModal = (id: number) => {
    setSelectedImageId(id);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const openPrevImage = () => {
    const currentGroup = idToGroup[selectedImageId];
    const groupImages = images.filter((image) => image.group === currentGroup);
    const currentIndex = groupImages.findIndex(
      (image) => image.id === selectedImageId
    );

    if (currentIndex !== -1) {
      const prevIndex =
        (currentIndex - 1 + groupImages.length) % groupImages.length;
      setSelectedImageId(groupImages[prevIndex].id);
    }
  };

  const openNextImage = () => {
    const currentGroup = idToGroup[selectedImageId];
    const groupImages = images.filter((image) => image.group === currentGroup);
    const currentIndex = groupImages.findIndex(
      (image) => image.id === selectedImageId
    );

    if (currentIndex !== -1) {
      const nextIndex = (currentIndex + 1) % groupImages.length;
      setSelectedImageId(groupImages[nextIndex].id);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        openPrevImage();
      } else if (event.key === "ArrowRight") {
        openNextImage();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <StyledTratamentoEControleParaAguaDeCaldeira>
      <div className="containers">
        <h1 className="resp-mobile">
          {" "}
          Tratamento e controle para água de Caldeira
        </h1>
        <div className="image-containers">
          {images
            .filter(
              (image) =>
                image.id === 1 ||
                image.id === 4 ||
                image.id === 7 ||
                image.id === 10 ||
                image.id === 13 ||
                image.id === 16 ||
                image.id === 19 ||
                image.id === 22 ||
                image.id === 25 ||
                image.id === 28
            )
            .map((image) => (
              <div
                className="pair"
                key={image.id}
                onClick={() => openModal(image.id)}
              >
                <div
                  className="image"
                  onContextMenu={(e: React.MouseEvent) => e.preventDefault()}
                >
                  <img src={image.src} alt={image.alt} loading="lazy" />
                </div>
                <div className="description">
                  <span>{image.description}</span>
                </div>
              </div>
            ))}
        </div>
      </div>

      {modalIsOpen && (
        <Modal onClick={handleModalClick}>
          <ModalCloseButton onClick={closeModal}>&times;</ModalCloseButton>
          <div>
            <button onClick={openPrevImage}>
              {" "}
              <AiOutlineArrowLeft />{" "}
            </button>
            <img
              key={selectedImageId}
              src={images.find((image) => image.id === selectedImageId)!.src}
              alt={images.find((image) => image.id === selectedImageId)!.alt}
            />
            <button onClick={openNextImage}>
              {" "}
              <AiOutlineArrowRight />{" "}
            </button>
          </div>
        </Modal>
      )}
    </StyledTratamentoEControleParaAguaDeCaldeira>
  );
};

export default TratamentoEControleParaAguaDeCaldeira;
