import styled from 'styled-components';

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;

  & > div {
    padding: 20px;
    border-radius: 5px;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    max-width: 800px;
    height: 300px;
    padding: 10px;
    box-shadow: 3px -1px 17px 0px #f3cf2d;
    -webkit-box-shadow: 3px -1px 17px 0px #f3cf2d;
    -moz-box-shadow: 3px -1px 17px 0px #f3cf2d;
  }

  & > div > img {
    background-size: 100% 100%;
    background: no-repeat center / cover;
    height: 120%;
    width: 100%;
  }

  & > div > button {
    color: white;
  }

  & > div > button:nth-child(3) {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 5px;
    background-color: #fff;
    color: #fff;
    right: 0px;
  }

  & > div > button:nth-child(1) {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 5px;
    background-color: #fff;
    color: #fff;
    left: 0px;
  }

  & > div > button {
    width: 40px;
    height: 40px;
    padding: 5px;
    position: absolute;
  }

  & > div > button > svg {
    width: 100%;
    height: 100%;
    color: #000;
  }

  @media (min-width: 480px) {
    & > div {
      height: 350px;
    }
  }

  @media (min-width: 720px) {
    & > div {
      height: 400px;
      padding: 50px;
    }
  }

  @media (min-width: 780px) {
    & > div {
      height: 500px;
      padding: 50px;
    }
  }
`;

export const ModalCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  color: #fff;
  border: none;
  font-size: 30px;
  cursor: pointer;
`;
