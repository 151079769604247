import { useState, useEffect } from "react";
import { StyledPecasEspeciaisParaDiversasAplicacoes } from "./style";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Modal, ModalCloseButton } from "../../Modal";

const images = [
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694298306/IMetal/Pe%C3%A7as%20especiais%20para%20diversas%20aplica%C3%A7%C3%B5es/Sistema_para_An%C3%A1lise_em_Tubbing_de_Inox_ysc32z.jpg",
    alt: "Sistema para Análise em Tubbing de Inox",
    description: "Sistema para Análise em Tubbing de Inox",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694298306/IMetal/Pe%C3%A7as%20especiais%20para%20diversas%20aplica%C3%A7%C3%B5es/Bacias_de_Conten%C3%A7%C3%A3o_em_PP_para_Diversas_Aplica%C3%A7%C3%B5es_ejvpll.jpg",
    alt: "Bacias de Contenção em PP para Diversas Aplicações",
    description: "Bacias de Contenção em PP para Diversas Aplicações",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694298306/IMetal/Pe%C3%A7as%20especiais%20para%20diversas%20aplica%C3%A7%C3%B5es/Bico_Injetor_de_Produto_-_C-PVC_swupzc.png",
    alt: "Bico Injetor de Produto - C-PVC",
    description: "Bico Injetor de Produto - C-PVC",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694298306/IMetal/Pe%C3%A7as%20especiais%20para%20diversas%20aplica%C3%A7%C3%B5es/Bico_Injetor_de_Produto_-_Inox_Alta_Temperatura_ijr9yj.png",
    alt: "Bico Injetor de Produto - Inox (Alta Temperatura)",
    description: "Bico Injetor de Produto - Inox (Alta Temperatura)",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694298306/IMetal/Pe%C3%A7as%20especiais%20para%20diversas%20aplica%C3%A7%C3%B5es/Bico_Injetor_de_Produto_-_PVC_SCH80_qd9wkf.png",
    alt: "Bico Injetor de Produto - PVC SCH80",
    description: "Bico Injetor de Produto - PVC SCH80",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694298305/IMetal/Pe%C3%A7as%20especiais%20para%20diversas%20aplica%C3%A7%C3%B5es/Caixa_met%C3%A1lica_em_A%C3%A7o_Carbono_br6adn.jpg",
    alt: "Caixa metálica em Aço Carbono",
    description: "Caixa metálica em Aço Carbono",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694298304/IMetal/Pe%C3%A7as%20especiais%20para%20diversas%20aplica%C3%A7%C3%B5es/Caixas_em_PP_com_Fechamento_em_Alum%C3%ADnio_para_Acondicionamento_de_Bombas_..._bst9th.jpg",
    alt: "Caixas em PP com Fechamento em Alumínio para Acondicionamento de Bombas",
    description:
      "Caixas em PP com Fechamento em Alumínio para Acondicionamento de Bombas",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694298304/IMetal/Pe%C3%A7as%20especiais%20para%20diversas%20aplica%C3%A7%C3%B5es/Conten%C3%A7%C3%B5es_em_PP_para_Diversas_Aplica%C3%A7%C3%B5es_zzqri8.jpg",
    alt: "Contenções em PP para Diversas Aplicações",
    description: "Contenções em PP para Diversas Aplicações",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694298304/IMetal/Pe%C3%A7as%20especiais%20para%20diversas%20aplica%C3%A7%C3%B5es/Estrutura_em_Inox_para_Agitador_em_Tanque_IBC_al5itg.jpg",
    alt: "Estrutura em Inox para Agitador em Tanque IBC",
    description: "Estrutura em Inox para Agitador em Tanque IBC",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694298303/IMetal/Pe%C3%A7as%20especiais%20para%20diversas%20aplica%C3%A7%C3%B5es/Filtro_de_Areia_em_A%C3%A7o_Inox_oahrfb.jpg",
    alt: "Filtro de Areia em Aço Inox",
    description: "Filtro de Areia em Aço Inox",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694298303/IMetal/Pe%C3%A7as%20especiais%20para%20diversas%20aplica%C3%A7%C3%B5es/Header_de_Distribui%C3%A7%C3%A3o_em_A%C3%A7o_Inox_oqudtd.jpg",
    alt: "Header de Distribuição em Aço Inox",
    description: "Header de Distribuição em Aço Inox",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694298303/IMetal/Pe%C3%A7as%20especiais%20para%20diversas%20aplica%C3%A7%C3%B5es/Header_para_Rot%C3%A2metro_d5e82g.jpg",
    alt: "Header para Rotâmetro",
    description: "Header para Rotâmetro",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694373840/Sistema_de_By-Pass_para_Medidor_de_Vaz%C3%A3o_Placa_em_PP_10mm_e_Tubula%C3%A7%C3%A3o_P..._tehxjv-removebg-preview_md1egx-removebg-preview_tygxbf.png",
    alt: "Sistema de By-Pass para Medidor de Vazão (Placa em PP 10mm e Tubulação P)",
    description:
      "Sistema de By-Pass para Medidor de Vazão (Placa em PP 10mm e Tubulação P)",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694298303/IMetal/Pe%C3%A7as%20especiais%20para%20diversas%20aplica%C3%A7%C3%B5es/Sistema_de_Dosagem_-_Bomba_Acoplada_ao_Tanque_p2fsv4.jpg",
    alt: "Sistema de Dosagem - Bomba Acoplada ao Tanque",
    description: "Sistema de Dosagem - Bomba Acoplada ao Tanque",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694298303/IMetal/Pe%C3%A7as%20especiais%20para%20diversas%20aplica%C3%A7%C3%B5es/Sistema_de_Dosagens_em_Tubo_de_PP_com_Conten%C3%A7%C3%A3o_para_Diversas_Aplica%C3%A7..._ktkfal.jpg",
    alt: "Sistema de Dosagens em Tubo de PP com Contenção para Diversas Aplicações",
    description:
      "Sistema de Dosagens em Tubo de PP com Contenção para Diversas Aplicações",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694296116/IMetal/Pe%C3%A7as%20especiais%20para%20diversas%20aplica%C3%A7%C3%B5es/Sistema_de_Dosagens_Completos_em_PP_com_Conten%C3%A7%C3%A3o_para_Diversas_Aplica%C3%A7..._x6dd1r.jpg",
    alt: "Sistema de Dosagens Completos em PP com Contenção para Diversas Aplicações",
    description:
      "Sistema de Dosagens Completos em PP com Contenção para Diversas Aplicações",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694296116/IMetal/Pe%C3%A7as%20especiais%20para%20diversas%20aplica%C3%A7%C3%B5es/Sistema_de_Filltragem_-_Estrutura_em_A%C3%A7o_Inox_e_Tubula%C3%A7%C3%B5es_PVC-U_Sch80_ngromu.jpg",
    alt: "Sistema de Filltragem - Estrutura em Aço Inox e Tubulações PVC-U Sch80",
    description:
      "Sistema de Filltragem - Estrutura em Aço Inox e Tubulações PVC-U Sch80",
  },
];

const PecasEspeciaisParaDiversasAplicacoes = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const openModal = (index: number) => {
    setSelectedImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const openPrevImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const openNextImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        openPrevImage();
      } else if (event.key === "ArrowRight") {
        openNextImage();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <StyledPecasEspeciaisParaDiversasAplicacoes>
      <div className="containers">
        <h1 className="resp-mobile">
          Peças especiais para diversas Aplicações
        </h1>
        <div className="image-containers">
          {images.map((image, index) => (
            <div className="pair" key={index} onClick={() => openModal(index)}>
              <div
                className="image"
                onContextMenu={(e: React.MouseEvent) => e.preventDefault()}
              >
                <img src={image.src} alt={image.alt} loading="lazy" />
              </div>
              <div className="description">
                <span>{image.description}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {modalIsOpen && (
        <Modal onClick={handleModalClick}>
          <ModalCloseButton onClick={closeModal}>&times;</ModalCloseButton>
          <div>
            <button onClick={openPrevImage}>
              {" "}
              <AiOutlineArrowLeft />{" "}
            </button>
            <img
              key={selectedImageIndex}
              src={images[selectedImageIndex].src}
              alt={images[selectedImageIndex].alt}
            />
            <button onClick={openNextImage}>
              {" "}
              <AiOutlineArrowRight />{" "}
            </button>
          </div>
        </Modal>
      )}
    </StyledPecasEspeciaisParaDiversasAplicacoes>
  );
};

export default PecasEspeciaisParaDiversasAplicacoes;
