import { useState, useEffect } from "react";
import { StyledResfriadoresDeAmostraParaAguaDeCaldeira } from "./style";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Modal, ModalCloseButton } from "../../Modal";

const images = [
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694374797/IMetal/Resfriadores%20amostra%20de%20%C3%A1gua%20para%20caldeira/Bico_Injetor_de_Produto_com_Sistema_de_Resfriamento_todo_em_Inox_mnkpwv.jpg",
    alt: "Bico Injetor de Produto com Sistema de Resfriamento todo em Inox",
    description:
      "Bico Injetor de Produto com Sistema de Resfriamento todo em Inox",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694374796/IMetal/Resfriadores%20amostra%20de%20%C3%A1gua%20para%20caldeira/M%C3%B3dulo_de_Resfriamento_Duplo_em_A%C3%A7o_Inox_com_Sistema_de_Prote%C3%A7%C3%A3o_Shield_ciyqmg.jpg",
    alt: "Módulo de Resfriamento Duplo em Aço Inox com Sistema de Proteção Shield",
    description:
      "Módulo de Resfriamento Duplo em Aço Inox com Sistema de Proteção Shield",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694374796/IMetal/Resfriadores%20amostra%20de%20%C3%A1gua%20para%20caldeira/M%C3%B3dulo_de_Resfriamento_em_A%C3%A7o_Inox_com_Sistema_de_Prote%C3%A7%C3%A3o_Shield_e_Pain..._sxwsbx.jpg",
    alt: "Módulo de Resfriamento em Aço Inox com Sistema de Proteção Shield e Pain Painel com Controle de Temperatura",
    description:
      "Módulo de Resfriamento em Aço Inox com Sistema de Proteção Shield e Pain Painel com Controle de Temperatura",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694374795/IMetal/Resfriadores%20amostra%20de%20%C3%A1gua%20para%20caldeira/M%C3%B3dulo_de_Resfriamento_em_A%C3%A7o_Inox_com_Sistema_de_Prote%C3%A7%C3%A3o_Shield_irozms.jpg",
    alt: "Módulo de Resfriamento em Aço Inox com Sistema de Proteção Shield",
    description:
      "Módulo de Resfriamento em Aço Inox com Sistema de Proteção Shield",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694374795/IMetal/Resfriadores%20amostra%20de%20%C3%A1gua%20para%20caldeira/M%C3%B3dulo_de_Resfriamento_Quadriplo_em_A%C3%A7o_Inox_com_Sistema_de_Prote%C3%A7%C3%A3o_Shi..._dff76j.jpg",
    alt: "Módulo de Resfriamento Quadriplo em Aço Inox com Sistema de Proteção Shield",
    description:
      "Módulo de Resfriamento Quadriplo em Aço Inox com Sistema de Proteção Shield",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694374795/IMetal/Resfriadores%20amostra%20de%20%C3%A1gua%20para%20caldeira/Resfriador_de_Amostra_em_A%C3%A7o_Inox_de_F%C3%A1cil_Locomo%C3%A7%C3%A3o_ucluf4.jpg",
    alt: "Resfriador de Amostra em Aço Inox de Fácil Locomoção",
    description: "Resfriador de Amostra em Aço Inox de Fácil Locomoção",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1694374795/IMetal/Resfriadores%20amostra%20de%20%C3%A1gua%20para%20caldeira/Resfriador_de_Amostra_em_A%C3%A7o_Inox_oblnty.jpg",
    alt: "Resfriador de Amostra em Aço Inox",
    description: "Resfriador de Amostra em Aço Inox",
  },
];

const ResfriadoresDeAmostraParaAguaDeCaldeira = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const openModal = (index: number) => {
    setSelectedImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const openPrevImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const openNextImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        openPrevImage();
      } else if (event.key === "ArrowRight") {
        openNextImage();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <StyledResfriadoresDeAmostraParaAguaDeCaldeira>
      <div className="containers">
        <h1 className="resp-mobile">
          Resfriadores de amostra para água de Caldeira
        </h1>
        <div className="image-containers">
          {images.map((image, index) => (
            <div className="pair" key={index} onClick={() => openModal(index)}>
              <div
                className="image"
                onContextMenu={(e: React.MouseEvent) => e.preventDefault()}
              >
                <img src={image.src} alt={image.alt} loading="lazy" />
              </div>
              <div className="description">
                <span>{image.description}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {modalIsOpen && (
        <Modal onClick={handleModalClick}>
          <ModalCloseButton onClick={closeModal}>&times;</ModalCloseButton>
          <div>
            <button onClick={openPrevImage}>
              {" "}
              <AiOutlineArrowLeft />{" "}
            </button>
            <img
              key={selectedImageIndex}
              src={images[selectedImageIndex].src}
              alt={images[selectedImageIndex].alt}
            />
            <button onClick={openNextImage}>
              {" "}
              <AiOutlineArrowRight />{" "}
            </button>
          </div>
        </Modal>
      )}
    </StyledResfriadoresDeAmostraParaAguaDeCaldeira>
  );
};

export default ResfriadoresDeAmostraParaAguaDeCaldeira;
