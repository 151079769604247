import { useState, useEffect } from "react";
import { StyledDiluidoresEPreparadosDeCloroEmPastilha } from "./style";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Modal, ModalCloseButton } from "../../Modal";

const images = [
  {
    id: 1,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701361862/IMetal/Diluidores%20e%20preparadores%20de%20cloro%20em%20pastilha/Diluidor_de_Cloro_em_Pastilha_todo_em_PP_-_Capacidade_50_Kg_1.0_wyccbb.jpg",
    alt: "Diluidor de Cloro em Pastilha todo em PP - Capacidade 50 Kg",
    description: "Diluidor de Cloro em Pastilha todo em PP - Capacidade 50 Kg",
    group: "Componentes",
  },
  {
    id: 2,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701361862/IMetal/Diluidores%20e%20preparadores%20de%20cloro%20em%20pastilha/Diluidor_de_Cloro_em_Pastilha_todo_em_PP_-_Capacidade_50_Kg_1.1_svlibc.jpg",
    alt: "Diluidor de Cloro em Pastilha todo em PP - Capacidade 50 Kg",
    description: "Diluidor de Cloro em Pastilha todo em PP - Capacidade 50 Kg",
    group: "Componentes",
  },
  {
    id: 3,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701361862/IMetal/Diluidores%20e%20preparadores%20de%20cloro%20em%20pastilha/Diluidor_de_Cloro_em_Pastilha_todo_em_PP_-_Capacidade_100_Kg_1.0_ona2tt.jpg",
    alt: "Diluidor de Cloro em Pastilha todo em PP - Capacidade 100 Kg",
    description: "Diluidor de Cloro em Pastilha todo em PP - Capacidade 100 Kg",
    group: "Componentes2",
  },
  {
    id: 4,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701361861/IMetal/Diluidores%20e%20preparadores%20de%20cloro%20em%20pastilha/Diluidor_de_Cloro_em_Pastilha_todo_em_PP_-_Capacidade_100_Kg_1.1_qiknhe.jpg",
    alt: "Diluidor de Cloro em Pastilha todo em PP - Capacidade 100 Kg",
    description: "Diluidor de Cloro em Pastilha todo em PP - Capacidade 100 Kg",
    group: "Componentes2",
  },
  {
    id: 5,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701361862/IMetal/Diluidores%20e%20preparadores%20de%20cloro%20em%20pastilha/Sistema_de_Dilui%C3%A7%C3%A3o_Preparo_e_Dosagem_de_Cloro_em_Pastilha_1.0_y2yhl1.jpg",
    alt: "Sistema de Diluição, Preparo e Dosagem de Cloro em Pastilha",
    description: "Sistema de Diluição, Preparo e Dosagem de Cloro em Pastilha",
    group: "Componentes3",
  },
  {
    id: 6,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701361862/IMetal/Diluidores%20e%20preparadores%20de%20cloro%20em%20pastilha/Sistema_de_Dilui%C3%A7%C3%A3o_Preparo_e_Dosagem_de_Cloro_em_Pastilha_1.1_qbnz8b.jpg",
    alt: "Sistema de Diluição, Preparo e Dosagem de Cloro em Pastilha",
    description: "Sistema de Diluição, Preparo e Dosagem de Cloro em Pastilha",
    group: "Componentes3",
  },
  {
    id: 7,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701361861/IMetal/Diluidores%20e%20preparadores%20de%20cloro%20em%20pastilha/Sistema_de_Dilui%C3%A7%C3%A3o_Preparo_e_Dosagem_de_Cloro_em_Pastilha_1.2_vunge4.jpg",
    alt: "Sistema de Diluição, Preparo e Dosagem de Cloro em Pastilha",
    description: "Sistema de Diluição, Preparo e Dosagem de Cloro em Pastilha",
    group: "Componentes3",
  },
  {
    id: 8,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701361861/IMetal/Diluidores%20e%20preparadores%20de%20cloro%20em%20pastilha/Sistema_de_Diluidores_de_Cloro_em_Pastilha_em_S%C3%A9rie_todo_em_PP_1.0_tjjfus.jpg",
    alt: "Sistema de Diluidores de Cloro em Pastilha em Série todo em PP",
    description:
      "Sistema de Diluidores de Cloro em Pastilha em Série todo em PP",
    group: "Componentes4",
  },
  {
    id: 9,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701361862/IMetal/Diluidores%20e%20preparadores%20de%20cloro%20em%20pastilha/Sistema_de_Diluidores_de_Cloro_em_Pastilha_em_S%C3%A9rie_todo_em_PP_1.1_htpfiw.jpg",
    alt: "Sistema de Diluidores de Cloro em Pastilha em Série todo em PP",
    description:
      "Sistema de Diluidores de Cloro em Pastilha em Série todo em PP",
    group: "Componentes4",
  },
  {
    id: 10,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701361862/IMetal/Diluidores%20e%20preparadores%20de%20cloro%20em%20pastilha/Skid_todo_em_PP_para_Preparo_e_Dosagem_de_Cloro_em_Pastilha_1.0_gvoqny.jpg",
    alt: "Skid todo em PP para Preparo e Dosagem de Cloro em Pastilha",
    description: "Skid todo em PP para Preparo e Dosagem de Cloro em Pastilha",
    group: "Componentes5",
  },
  {
    id: 11,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701361861/IMetal/Diluidores%20e%20preparadores%20de%20cloro%20em%20pastilha/Skid_todo_em_PP_para_Preparo_e_Dosagem_de_Cloro_em_Pastilha_1.1_fcebmf.jpg",
    alt: "Skid todo em PP para Preparo e Dosagem de Cloro em Pastilha",
    description: "Skid todo em PP para Preparo e Dosagem de Cloro em Pastilha",
    group: "Componentes5",
  },
  {
    id: 12,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701361861/IMetal/Diluidores%20e%20preparadores%20de%20cloro%20em%20pastilha/Skid_todo_em_PP_para_Preparo_e_Dosagem_de_Cloro_em_Pastilha_1.2_fujruw.jpg",
    alt: "Skid todo em PP para Preparo e Dosagem de Cloro em Pastilha",
    description: "Skid todo em PP para Preparo e Dosagem de Cloro em Pastilha",
    group: "Componentes5",
  },
];

const idToGroup: Record<number, string> = {};
images.forEach((image) => {
  idToGroup[image.id] = image.group;
});

const DiluidoresEPreparadosDeCloroEmPastilha = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(1);

  const openModal = (id: number) => {
    setSelectedImageId(id);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const openPrevImage = () => {
    const currentGroup = idToGroup[selectedImageId];
    const groupImages = images.filter((image) => image.group === currentGroup);
    const currentIndex = groupImages.findIndex(
      (image) => image.id === selectedImageId
    );

    if (currentIndex !== -1) {
      const prevIndex =
        (currentIndex - 1 + groupImages.length) % groupImages.length;
      setSelectedImageId(groupImages[prevIndex].id);
    }
  };

  const openNextImage = () => {
    const currentGroup = idToGroup[selectedImageId];
    const groupImages = images.filter((image) => image.group === currentGroup);
    const currentIndex = groupImages.findIndex(
      (image) => image.id === selectedImageId
    );

    if (currentIndex !== -1) {
      const nextIndex = (currentIndex + 1) % groupImages.length;
      setSelectedImageId(groupImages[nextIndex].id);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        openPrevImage();
      } else if (event.key === "ArrowRight") {
        openNextImage();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <StyledDiluidoresEPreparadosDeCloroEmPastilha>
      <div className="containers">
        <h1 className="resp-mobile">
          {" "}
          Diluidores e preparadores de cloro em Pastilha
        </h1>
        <div className="image-containers">
          {images
            .filter(
              (image) =>
                image.id === 1 ||
                image.id === 3 ||
                image.id === 5 ||
                image.id === 8 ||
                image.id === 10
            )
            .map((image) => (
              <div
                className="pair"
                key={image.id}
                onClick={() => openModal(image.id)}
              >
                <div
                  className="image"
                  onContextMenu={(e: React.MouseEvent) => e.preventDefault()}
                >
                  <img src={image.src} alt={image.alt} loading="lazy" />
                </div>
                <div className="description">
                  <span>{image.description}</span>
                </div>
              </div>
            ))}
        </div>
      </div>

      {modalIsOpen && (
        <Modal onClick={handleModalClick}>
          <ModalCloseButton onClick={closeModal}>&times;</ModalCloseButton>
          <div>
            <button onClick={openPrevImage}>
              {" "}
              <AiOutlineArrowLeft />{" "}
            </button>
            <img
              key={selectedImageId}
              src={images.find((image) => image.id === selectedImageId)!.src}
              alt={images.find((image) => image.id === selectedImageId)!.alt}
            />
            <button onClick={openNextImage}>
              {" "}
              <AiOutlineArrowRight />{" "}
            </button>
          </div>
        </Modal>
      )}
    </StyledDiluidoresEPreparadosDeCloroEmPastilha>
  );
};

export default DiluidoresEPreparadosDeCloroEmPastilha;
