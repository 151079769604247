import styled from 'styled-components';

export const StyledBannerHome = styled.section`
  margin-top: 100px;
  width: 100%;
  max-width: 100%;
  height: 750px;
  background: url('https://res.cloudinary.com/dut76ufs0/image/upload/v1702072424/FUNDO_-_SITE_dysgzf-min_ij2kfi.jpg');

  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center top;
  object-fit: cover;

  & > div > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
  }

  & > div > div > h1 {
    color: white;
    font-size: 40px;
    text-shadow: 1px 1px 1px black;
    position: relative;
    height: 50px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  & > div > div > h2 {
    color: white;
    font-size: 42px;
    margin-top: 20px;
    text-shadow: 1px 1px 1px black;
  }

  & > div > div > p {
    color: black;
    height: auto;
    width: 50%;
    font-size: 15px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: 1px 1px 1px white;
  }

  & > div > div > div {
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
  }

  & > div > div > div > a {
    background-color: #000;
    color: white;
    padding: 20px 10px;
    display: flex;
    width: 100%;
    max-width: 200px;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
  }

  & > div > div > div > a:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid #fff;
    transition: 0.5s;
  }


   @media (min-width: 320px) and (max-width: 759px) {
   
  & > div > div > h2 {
    color: white;
    font-size: 1.5em;
    text-shadow: 1px 1px 1px black;
    width: 50%;
  }

  div > div > p {
    color: black;
    height: auto;
    width: 43%;
    font-size: 1em;
    display: flex;
    text-align: start;
    text-shadow: 1px 1px 1px white;
  }

  div > div > div > a {
    background-color: #000;
    color: white;
    padding: 20px 10px;
    display: flex;
    flex-direction: row;
    max-width: 200px;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
  }
  
  } 

  @media (min-width: 780px) {
    background-size: 100% 100%;

    & > div > div {
      width: 60%;
    }

    & > div > div > h1 {
      font-size: 70px;
    }

    & > div > div > p {
      font-size: 19px;
      line-height: 40px;
      width: 70%;
    }

    & > div > div > div {
      flex-direction: row;
      text-align: left;
      justify-content: left;
    }
  }
`;

export const StyledSectionAboutUs = styled.section`
  height: auto;
  min-height: 500px;
  background-color: white;
  width: 100%;
  padding: 90px 0;
  background: url('https://res.cloudinary.com/dut76ufs0/image/upload/v1691007816/IMetal/contact_bg_syon14.jpg');

  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border: 2px solid #f3cf2d;
    border-radius: 10px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 3px -1px 17px 0px #f3cf2d;
    -webkit-box-shadow: 3px -1px 17px 0px #f3cf2d;
    -moz-box-shadow: 3px -1px 17px 0px #f3cf2d;
  }

  & > div > li {
    text-align: left;
    padding-left: 20px;
    width: 100%;
  }

  & > div > li > h2 {
    font-size: 50px;
    color: #000000;
    text-align: center;
    text-shadow: 1px 1px 1px black;
    padding: 30px;
  }

  & > div > li > p {
    font-size: 20px;
    color: #000000;
    margin-top: 20px;
  }

  & > div > li > p > span {
    font-style: italic;
  }

  & > div > figure {
    width: 100%;
    height: 100%;
    background-color: #fff;
  }

  & > div > figure > img {
    width: 100%;
    height: 100%;
    background-size: 100%;
    object-fit: cover;
  }

  @media (min-width: 780px) {
    & > div > li {
      width: 50%;
      height: 100%;
    }

    & > div > figure {
      width: 50%;
    }
  }
`;

export const StyledSectionCards = styled.section`
  //https://res.cloudinary.com/dut76ufs0/image/upload/v1691007816/IMetal/bg_about_bqhkb7.png
  background: url(https://res.cloudinary.com/dut76ufs0/image/upload/v1691007816/IMetal/bg_about_bqhkb7.png);
  min-height: 600px;
  height: auto;
  padding: 90px 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  padding: 20px;
  gap: 20px;
  width: 100%;
  place-items: center;

  & > div {
    background-color: #efefef;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    gap: 20px;
    padding: 20px;
    height: auto;
  }

  & > div > div {
    background-color: #000000;
    height: 70px;
    width: 70px;
    padding: 20px;
    border-radius: 50%;
  }

  & > div > div > svg {
    color: white;
    height: 100%;
    width: 100%;
  }

  div {
    box-shadow: 3px -1px 17px 0px #f3cf2d;
    -webkit-box-shadow: 3px -1px 17px 0px #f3cf2d;
    -moz-box-shadow: 3px -1px 17px 0px #f3cf2d;
  }

  & > div > h2 {
    font-size: 35px;
    font-weight: bold;
    color: #000000;
  }

  & > div > p {
    font-size: 16px;
    color: 000000; 
  }

  & > div > a {
    background-color: #f3cf2d;
    color: #000 !important;
    width: 150px;
    padding: 15px 0;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    align-content: center;
  }

  & > div > a:hover {
    background-color: #007bff;
    color: #000;
  }

  @media (min-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);

    & > div {
      height: 400px;
    }
  }

  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
`;

export const StyledSectionProdutos = styled.section`
  min-height: 800px;
  background-color: #000;
  padding: 20px;

  & > div {
    text-align: center;
  }

  & > div > h2 {
    color: #fff;
    font-size: 45px;
    font-weight: bold;
    margin-top: 20px;
  }

  & > div > p {
    color: #fff;
    font-size: 18px;
    margin-top: 10px;
  }
`;

export const StyledSectionParceiros = styled.section`
  background: url(https://res.cloudinary.com/dut76ufs0/image/upload/v1691007816/IMetal/bg_about_bqhkb7.png);
  height: auto;
  width: 100%;
  text-align: center;
  background-size: 100%;
  padding: 40px 20px;

  & > h2 {
    font-size: 45px;
    color: #000000;
  }

  // versão antiga com 8 cards acima e 2 abaixo, possibilitando aumento
  /* & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 50px;
  } */

  & > div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 80%;
    margin-top: 50px;
    margin-left: 150px;
  }

  & > div > div {
    margin: 0;
    width: calc(33.33% - 20px);
    max-width: 200px;
    margin: 10px;
    text-align: center;
  }

  & > div > div > img {
    max-width: 100%;
    max-height: 100%;
  }

  @media (min-width: 320px) and (max-width: 759px) {
    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 50px;
      margin-left: 10px;
    }
  }
`;

export const StyledSectionContato = styled.section`
  background-color: #f0f0f0;
  min-height: 600px;
  padding: 60px 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    background-color: #f3cf2d;
    padding: 10px;
    text-align: center;
    width: 90%;
  }

  & > div > form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    margin-top: 40px;
  }

  & > div > h2 {
    font-size: 50px;
    color: #000;
  }

  & > div > form > input {
    height: 60px;
    border: none;
    padding-left: 10px;
    font-weight: 500;
    font-size: 17px;
  }

  & > div > form > textarea {
    min-height: 100px;
    padding: 10px;
    font-size: 17px;
    max-width: 100%;
  }

  & > div > form > span {
    color: red;
    font-weight: bold;
    text-align: left;
  }

  & > div > form > button {
    font-size: 18px;
    transition: ease-in all 0.5s;
    background-color: #000000;
    color: #fff;
    max-width: 217px;
    width: 100%;
    display: block;
    margin-top: 10px !important;
    font-weight: 600;
    height: 60px;
    margin: 0 auto;
  }

  div > form > button:hover {
    background-color: #ddd;
    transition: ease-in all 0.5s;
    color: #000000;
  }

  @media (min-width: 760px) {
    & > div {
      width: 70%;
      padding: 30px;
    }
  }

  @media (min-width: 1360px) {
    & > div {
      width: 60%;
      padding: 30px;
    }
  }
`;
