import React from "react";
import { StyledFooterComponent } from "./style";
import {
  FaLocationDot,
  FaPhoneVolume,
  FaWhatsapp,
  FaFacebook,
  FaLinkedin,
  FaInstagram,
} from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { BiLogoTwitter } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";

const WhatsAppButtons = () => {
  const openWhatsApp = () => {
    const phoneNumber = "+5511998133448";
    const message = "Olá! Gostaria de fazer um orçamento";
    const encodedMessage = encodeURIComponent(message);
    window.open(
      `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`,
      "_blank",
      "noopener"
    );
  };

  return (
    <div onClick={openWhatsApp}>
      <FaWhatsapp />
    </div>
  );
};

const LinkedInButton = () => {
  const openLinkedIn = () => {
    const linkedInUrl =
      "https://www.linkedin.com/company/imetal-equipamentos-e-montagens-industriais/";

    window.open(linkedInUrl, "_blank", "noopener");
  };

  return (
    <div onClick={openLinkedIn}>
      <FaLinkedin />
    </div>
  );
};

export const Footer = () => {
  const navigate = useNavigate();
  let location = useLocation();

  const redirectToSectionContact = () => {
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        const sectionClient: any = document.getElementById("contact");
        sectionClient.scrollIntoView({ behavior: "smooth" });
      }, 0.2);
    } else {
      const sectionClient: any = document.getElementById("contact");
      sectionClient.scrollIntoView({ behavior: "smooth" });
    }
    return;
  };

  return (
    <div>
      <StyledFooterComponent>
        <p>
          Deixe-nos fazer parte da sua empresa oferecendo-lhes o melhor custo
          benefícios do mercado.
        </p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.7660072271474!2d-46.2663696!3d-23.540917!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce77677c407ed9%3A0xb453ab6161b9b80d!2sAv.%20Guilherme%20George%2C%201984%20-%20Jundiapeba%2C%20Mogi%20das%20Cruzes%20-%20SP!5e0!3m2!1spt-BR!2sbr!4v1691372123680!5m2!1spt-BR!2sbr"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Mapa Interativo da Localização"
        />
        <p>
          {" "}
          <FaLocationDot /> Av. Guilherme George, 1984, Real Park Tiete, Mogi
          das Cruzes - SP CEP 08750-540{" "}
        </p>
        <p>
          {" "}
          <FaPhoneVolume /> +55 (11) 4735-5232
        </p>
        <button onClick={redirectToSectionContact}>
          {" "}
          <MdEmail /> imetal@imetalmontagens.com.br{" "}
        </button>
        <div>
          <div>
            <WhatsAppButtons />
          </div>
          <div>
            <LinkedInButton />
          </div>
        </div>
        <p>© Copyright 2023 Todos os Direitos reservados</p>
        <p> By DreiCode</p>
      </StyledFooterComponent>
    </div>
  );
};
