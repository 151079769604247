import { useEffect, useRef, useState } from "react";
import { StyleHeaderComponents } from "./style";
import { BiRightArrowAlt } from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useLocation, useNavigate } from "react-router-dom";

export const Header = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [subMenuMobile, setSubMenuMobile] = useState(false);
  const [subMenuMobileEquipamentos, setSubMenuMobileEquipamentos] =
    useState(false);

  const navigate = useNavigate();
  let location = useLocation();

  const redirectToSectionAboutUs = () => {
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        const sectionAboutUs: any = document.getElementById("aboutUs");
        sectionAboutUs.scrollIntoView({ behavior: "smooth" });
      }, 0.2);
    } else {
      const sectionAboutUs: any = document.getElementById("aboutUs");
      sectionAboutUs.scrollIntoView({ behavior: "smooth" });
    }
  };

  const redirectToSectionContact = () => {
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        const sectionContact: any = document.getElementById("contact");
        sectionContact.scrollIntoView({ behavior: "smooth" });
      }, 0.2);
    } else {
      const sectionContact: any = document.getElementById("contact");
      sectionContact.scrollIntoView({ behavior: "smooth" });
    }
  };

  const redirectToSectionClient = () => {
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        const sectionClient: any = document.getElementById("clients");
        sectionClient.scrollIntoView({ behavior: "smooth" });
      }, 0.2);
    } else {
      const sectionClient: any = document.getElementById("clients");
      sectionClient.scrollIntoView({ behavior: "smooth" });
    }
  };

  const openMenuHamburger = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const toggleMenuMobile = () => {
    setSubMenuMobile(!subMenuMobile);
  };
  const toggleMenuMobileEquipamentos = () => {
    setSubMenuMobileEquipamentos(!subMenuMobileEquipamentos);
  };

  const [isSubMenuOpen, setSubMenuOpen] = useState(false);
  const [isSubEquipamentosOpen, setSubEquipamentosOpen] = useState(false);

  const openSubMenu = () => {
    setSubMenuOpen(true);
  };
  const closeSubMenu = () => {
    setSubMenuOpen(false);
  };
  const openSubEquipamentos = () => {
    setSubEquipamentosOpen(true);
  };
  const closeSubEquipamentos = () => {
    setSubEquipamentosOpen(false);
  };

  const menuRef = useRef<HTMLDivElement>(null);
  const menuBtnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        menuBtnRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        !menuBtnRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <StyleHeaderComponents>
      <figure onClick={() => navigate("/")}>
        <img
          src={
            "https://res.cloudinary.com/dut76ufs0/image/upload/v1691007816/IMetal/Logo-imetal-e1502207374888_hszk9m.png"
          }
          alt="imagem logo da empresa"
        />
      </figure>
      <button onClick={openMenuHamburger} ref={menuBtnRef}>
        <GiHamburgerMenu />
      </button>
      {isDropdownOpen && (
        <div id="menu-hamburguerMobile" ref={menuRef}>
          <ul></ul>
          <li onClick={openMenuHamburger}>
            <Link to="/">Home</Link>
          </li>
          <li
            onClick={() => {
              openMenuHamburger();
              redirectToSectionAboutUs();
            }}
          >
            <button>Sobre Nós</button>
          </li>
          <li>
            <button onClick={toggleMenuMobile}>Produtos</button>
            {subMenuMobile && (
              <div>
                <ul>
                  <li onClick={openMenuHamburger}>
                    <Link to="/montagensIndustriais">
                      Montagens Industriais
                    </Link>
                  </li>
                  <li onClick={openMenuHamburger}>
                    <Link to="/projetosDeEngenharia">
                      Projetos de Engenharia
                    </Link>
                  </li>
                  <li>
                    <button onClick={toggleMenuMobileEquipamentos}>
                      Equipamentos{" "}
                    </button>
                    {subMenuMobileEquipamentos && (
                      <div>
                        <ul>
                          <li onClick={openMenuHamburger}>
                            <Link
                              to={
                                "/tratamentoEControleDeAguaParaTorreDeResfriamento"
                              }
                            >
                              Tratamento e controle de água para torre de
                              resfriamento
                            </Link>
                          </li>
                          <li onClick={openMenuHamburger}>
                            <Link to={"/tratamentoEControleParaAguaDeCaldeira"}>
                              Tratamento e controle de água para caldeira
                            </Link>
                          </li>
                          <li onClick={openMenuHamburger}>
                            <Link
                              to={"/diluidoresEpreparadosDeCloroEmPastilha"}
                            >
                              Diluidores e Preparados de Cloro em Pastilha
                            </Link>
                          </li>
                          <li onClick={openMenuHamburger}>
                            <Link to={"/preparadoresDePolimeroEmEmulsao"}>
                              Preparadores de Polimeros em Emulsão
                            </Link>
                          </li>
                          <li onClick={openMenuHamburger}>
                            <Link
                              to={"/resfriadoresDeAmostraParaAguaDeCaldeira"}
                            >
                              Resfriadores de amostra para água de caldeira
                            </Link>
                          </li>
                          <li onClick={openMenuHamburger}>
                            <Link to={"/pecasEspeciaisParaDiversasAplicacoes"}>
                              Peças especiais para diversas aplicações
                            </Link>
                          </li>
                        </ul>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            )}
          </li>
          <li
            onClick={() => {
              openMenuHamburger();
              redirectToSectionContact();
            }}
          >
            <button>Orçamentos</button>
          </li>
          <li
            onClick={() => {
              openMenuHamburger();
              redirectToSectionClient();
            }}
          >
            <button>Clientes</button>
          </li>
        </div>
      )}

      <ul>
        <li>
          <Link to={"/"}>Home</Link>
        </li>
        <li onClick={redirectToSectionAboutUs}>
          <button>Sobre Nós</button>
        </li>
        <li onMouseEnter={openSubMenu} onMouseLeave={closeSubMenu}>
          <button>Produtos</button>
          {isSubMenuOpen && (
            <div>
              <li>
                <Link to="/montagensIndustriais">Montagens Industriais</Link>
              </li>
              <li>
                <Link to="/projetosDeEngenharia">Projetos de Engenharia</Link>
              </li>
              <li
                onMouseEnter={openSubEquipamentos}
                onMouseLeave={closeSubEquipamentos}
              >
                <button>Equipamentos </button>
                {isSubEquipamentosOpen && (
                  <div>
                    <li>
                      <Link to="/tratamentoEControleDeAguaParaTorreDeResfriamento">
                        Tratamento e controle de água para torre de resfriamento
                      </Link>
                    </li>
                    <li>
                      <Link to="/tratamentoEControleParaAguaDeCaldeira">
                        Tratamento e controle de água para caldeira
                      </Link>
                    </li>
                    <li>
                      <Link to="/diluidoresEPreparadosDeCloroEmPastilha">
                        Diluidores e Preparados de Cloro em Pastilha
                      </Link>
                    </li>
                    <li>
                      <Link to="/preparadoresDePolimeroEmEmulsao">
                        Preparadores de Polimeros em Emulsão
                      </Link>
                    </li>
                    <li>
                      <Link to="/resfriadoresDeAmostraParaAguaDeCaldeira">
                        Resfriadores de amostra para água de caldeira
                      </Link>
                    </li>
                    <li>
                      <Link to="/pecasEspeciaisParaDiversasAplicacoes">
                        Peças especiais para diversas aplicações
                      </Link>
                    </li>
                  </div>
                )}
              </li>
            </div>
          )}
        </li>
        <li></li>
        <li>
          <button onClick={redirectToSectionContact}>Orçamentos</button>
        </li>
        <li>
          <button onClick={redirectToSectionClient}>Clientes</button>
        </li>
      </ul>
    </StyleHeaderComponents>
  );
};
