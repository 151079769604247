import styled from 'styled-components';

export const StyledPecasEspeciaisParaDiversasAplicacoes = styled.section`
 margin-top: 140px;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.containers {
  padding-top: 12vh;
  padding-bottom: 5vh;
}

.containers h1 {
  font-size: 50px;
  font-weight: bold;
  padding: 15px;
  color: 00000;
  text-align: center;
  margin-top: -100px;
}

.image {
  border: 10px solid #333;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  width: 350px;
}

.image img {
  max-width: 100%;
  height: auto;
  transition: 0.2s linear;
}

.image-containers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-top: 40px;
}

.pair {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.description {
  font-size: 18px;
  color: 00000;
  margin-top: 10px;
  text-align: center;
  width: 350px;
  height: 60px;
}

// tamanho dos cards normal sem zoom
.image-containers .image {
  height: 250px;
  width: 350px;
  border: 10px solid #333;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
}
.image-containers .image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: 0.2s linear;
}

.image-containers .image:hover img {
  transform: scale(1.1);
}
`;

export const Modal = styled.div`
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(0, 0, 0, 0.7);
display: flex;
justify-content: center;
align-items: center;
z-index: 2000;

& > div {
  padding: 20px;
  border-radius: 5px;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 90%;
  max-width: 700px;
  height: 300px;
  padding: 10px;
}

& > div > img {
  background-size: 100% 100%;
  background: no-repeat center / cover;
  height: 100%;
  width: 100%;
}

& > div > button {
  color: white;
}

& > div > button:nth-child(3) {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 5px;
  background-color: #fff;
  color: #fff;
  right: 0px;
}

& > div > button:nth-child(1) {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 5px;
  background-color: #fff;
  color: #fff;
  left: 0px;
}

& > div > button {
  width: 40px;
  height: 40px;
  padding: 5px;
  position: absolute;
}

& > div > button > svg {
  width: 100%;
  height: 100%;
  color: #000;
}

@media (min-width: 480px) {
  & > div {
    height: 350px;
  }
}

@media (min-width: 720px) {
  & > div {
    height: 400px;
    padding: 50px;
  }
}

@media (min-width: 780px) {
  & > div {
    height: 500px;
    padding: 50px;
  }
}
`;

export const ModalCloseButton = styled.button`
position: absolute;
top: 10px;
right: 10px;
background: none;
color: #fff;
border: none;
font-size: 30px;
cursor: pointer;
`;