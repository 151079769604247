import { StyeledCard, StyledPageValores } from "./style";
import { Swiper, SwiperSlide } from "swiper/react";
import { BsGraphUpArrow, BsGear, BsPeople } from "react-icons/bs";
import { MdDiversity1 } from "react-icons/md";
import { GiRadioTower } from "react-icons/gi";
import { VscCheckAll } from "react-icons/vsc";
import { AiOutlineTeam, AiOutlineSafetyCertificate } from "react-icons/ai";
import { PiCertificateLight, PiLeafThin } from "react-icons/pi";
import { EffectCoverflow, Pagination, A11y } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { StyleHeaderComponents } from "./style";

export const Valores = () => {
  return (
    <StyleHeaderComponents>
      <StyledPageValores>
        <h2>Valores</h2>
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          // slidesPerView={"auto"}
          slidesPerView={2}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          keyboard={{ enabled: true }}
          modules={[EffectCoverflow, Pagination, A11y]}
        >
          <SwiperSlide>
            <StyeledCard>
              <div>
                <BsGear />
              </div>
              <h2>Inovação</h2>
              <p>
                Estamos comprometidos em impulsionar a inovação contínua em
                nossos equipamentos, desenvolvendo soluções que antecipem as
                necessidades do mercado e definam novos padrões de desempenho.
              </p>
            </StyeledCard>
          </SwiperSlide>

          <SwiperSlide>
            <StyeledCard>
              <div>
                <PiCertificateLight />
              </div>
              <h2>Qualidade</h2>
              <p>
                Colocamos a qualidade em primeiro lugar, assegurando que nossos
                equipamentos sejam duráveis, confiáveis e construídos com os
                mais altos padrões para garantir a satisfação dos nossos
                clientes.
              </p>
            </StyeledCard>
          </SwiperSlide>

          <SwiperSlide>
            <StyeledCard>
              <div>
                <PiLeafThin />
              </div>
              <h2>Sustentabilidade</h2>
              <p>
                Valorizamos a sustentabilidade em todas as etapas, desde o
                design até o descarte, buscando minimizar o impacto ambiental
                dos nossos produtos e promovendo práticas responsáveis.
              </p>
            </StyeledCard>
          </SwiperSlide>

          <SwiperSlide>
            <StyeledCard>
              <div>
                <BsPeople />
              </div>
              <h2>Compromisso com o Cliente</h2>
              <p>
                Nossa dedicação ao cliente é fundamental. Estamos empenhados em
                entender suas necessidades e fornecer soluções personalizadas
                que superem suas expectativas.
              </p>
            </StyeledCard>
          </SwiperSlide>

          <SwiperSlide>
            <StyeledCard>
              <div>
                <AiOutlineSafetyCertificate />
              </div>
              <h2>Integridade</h2>
              <p>
                Pautamos nossas ações na honestidade, transparência e ética.
                Mantemos nossos compromissos e construímos relacionamentos
                baseados na confiança.
              </p>
            </StyeledCard>
          </SwiperSlide>

          <SwiperSlide>
            <StyeledCard>
              <div>
                <AiOutlineTeam />
              </div>
              <h2>Trabalho em Equipe</h2>
              <p>
                Reconhecemos que o sucesso é resultado do esforço coletivo.
                Fomentamos um ambiente de trabalho colaborativo onde ideias e
                habilidades se complementam.
              </p>
            </StyeledCard>
          </SwiperSlide>

          <SwiperSlide>
            <StyeledCard>
              <div>
                <GiRadioTower />
              </div>
              <h2>Responsabilidade Social</h2>
              <p>
                Assumimos a responsabilidade de contribuir positivamente para as
                comunidades onde operamos, promovendo o desenvolvimento local e
                apoiando causas sociais relevantes.
              </p>
            </StyeledCard>
          </SwiperSlide>

          <SwiperSlide>
            <StyeledCard>
              <div>
                <VscCheckAll />
              </div>
              <h2>Excelência Operacional</h2>
              <p>
                Buscamos constantemente a excelência em todas as áreas da nossa
                operação, desde a fabricação até o atendimento ao cliente, para
                alcançar resultados superiores.
              </p>
            </StyeledCard>
          </SwiperSlide>

          <SwiperSlide>
            <StyeledCard>
              <div>
                <MdDiversity1 />
              </div>
              <h2>Diversidade e Inclusão</h2>
              <p>
                Valorizamos a diversidade de perspectivas e talentos, criando um
                ambiente inclusivo onde todos são respeitados e têm a
                oportunidade de contribuir.
              </p>
            </StyeledCard>
          </SwiperSlide>

          <SwiperSlide>
            <StyeledCard>
              <div>
                <BsGraphUpArrow />
              </div>
              <h2>Aprendizado Contínuo</h2>
              <p>
                Estamos comprometidos com o aprendizado constante e o
                aprimoramento das nossas habilidades e conhecimentos para nos
                mantermos na vanguarda do setor.
              </p>
            </StyeledCard>
          </SwiperSlide>
        </Swiper>
      </StyledPageValores>
    </StyleHeaderComponents>
  );
};
