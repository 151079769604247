import styled from 'styled-components';

export const StyleHeaderComponents = styled.header`
  width: 100%;
  height: 104px;
  background: url('https://res.cloudinary.com/dut76ufs0/image/upload/v1691007816/IMetal/header_dt9vlu.jpg');
  background-position: 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;

  & > button {
    height: 40px;
    width: 40px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 70%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
    background-color: #151414;
    padding: 10px;
  }

  & > div > li {
    margin-top: 20px;
  }

  & > div > li > a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: white;
    font-size: 15px;
    font-weight: bold;
  }

  & > div > li > div {
    margin-top: 15px;
    padding-left: 5px;
  }

  & > div > li > button {
    color: white;
    font-size: 15px;
    display: flex;
    font-weight: 600;
    border: none;
    background-color: transparent;
    height: 15px;
    align-items: center;
    justify-content: space-between;
  }

  & > div > li > button > svg {
    height: 10px;
    width: 10px;
  }

  & > div > li > div > ul > li > a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: white;
    font-size: 13px;
    font-weight: 600;
    margin-top: 10px;
  }

  & > div > li > div > ul > li > div {
    margin-top: 15px;
    padding-left: 10px;
  }

  & > div > li > div > ul > li > button {
    color: white;
    font-size: 13px;
    display: flex;
    font-weight: 600;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    width: 100%;
  }

  & > div > li > div > li > button > svg {
    width: 10px;
    height: 10px;
  }

  & > div > li > div > ul > li > div > ul > li > a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: white;
    font-size: 12px;
    font-weight: 500;
    margin-top: 10px;
    z-index: 1000;
  }

  & > div > li > a:hover {
    svg {
      rotate: 90deg;
    }
  }

  & > button > svg {
    width: 100%;
    height: 100%;
  }

  figure {
    box-sizing: border-box !important;
    padding: 10px !important;
    height: 100% !important;
    width: 250px !important;
  }

  figure > img {
    width: 100% !important;
    height: 100% !important;
  }

  & > ul {
    display: none;
    align-items: center;
    gap: 1rem;
  }

  & > ul > li {
    position: relative;
  }

  & > ul > li > a {
    color: black;
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  & > ul > li > button {
    color: black;
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  & > ul > li > div {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    position: absolute;
    bottom: -130px;
    right: 0;
    background-color: #f3cf2d;
    height: 140px;
    z-index: 2000;
  }

  & > ul > li > div > li > div {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    position: absolute;
    bottom: -180px;
    right: -200px;
    background-color: #f3cf2d;
    height: auto;
    width: 200px;
    padding: 10px;
    z-index: 2000;
  }

  & > ul > li > div > li > div > li > a {
    color: black;
    font-size: 11px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 10px;
  }

  & > ul > li > div > li > div > li > a:hover {
    color: white;

    svg {
      rotate: 90deg;
    }
  }

  & > ul > li > div > li > a:hover {
    color: white;
  }

  & > ul > li > div > li > button:hover {
    color: white;

    svg {
      rotate: 90deg;
    }
  }

  & > ul > li > div > li > a {
    color: black;
    font-size: 14px;
    font-weight: bold;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  & > ul > li > div > li > button {
    color: black;
    font-size: 14px;
    font-weight: bold;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  & > ul > li > a:hover {
    border-bottom: 1px solid white;
    color: white;
  }

  & > ul > li > button:hover {
    border-bottom: 1px solid white;
    color: white;

    svg {
      rotate: 90deg;
    }
  }

  @media (min-width: 320px) {
    & > div {
      width: 50%;
    }
  }

  @media (min-width: 700px) {
    & > div {
      width: 40%;
    }
  }

  @media (min-width: 700px) {
    & > div {
      width: 30%;
    }
  }

  @media (min-width: 980px) {
    & > ul {
      display: flex;
    }

    & > button {
      display: none;
    }
  }
`;
