import styled from 'styled-components';

export const StyledDiv = styled.div`
  width: 100%;
  height: auto;
  min-height: 750px;
  display: flex;
  justify-content: center;
  align-items: center;

  .swiper {
    padding: 25px 10px;
    width: 100%;
    height: auto;
    position: relative;
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-width: 100%;
    gap: 20px;
    padding: 20px;
  }

  .swiper-slide > div {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .swiper-slide > a {
    margin-top: 20px;
    background-color: #ffffff;  
    color: black;  
    padding: 15px 20px; 
    width: 200px;
    text-align: center;  
    text-decoration: none;  
    border-radius: 8px;  
    display: inline-block;  
    transition: background-color 0.3s ease;
  }

  button {
    color: #000000 !important;
    font-weight: 400;
  }

   .swiper-slide > a:hover {
    background-color: #007bff;
    transition: 0.5s;
    color: #000;
  }

  .swiper-pagination-bullet-active {
    background-color: #f3cf2d;
  }

  .swiper-pagination-bullet {
    background-color: #fff;
  }

  .swiper-button-prev,
  .swiper-button-next {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    height: 20px;
    color: #fff;
    transition: background-color 0.3s, color 0.3s;
  }

  .swiper-button-prev:hover,
  .swiper-button-next:hover {
    //color: yellow;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

  button {
    font-size: 16px;
    color: #fff;
  }

  @media (min-width: 720px) {

    .swiper-button-prev {
      position: none;
      left: 0;
      display: flex;
      justify-content: left;
    }

    .swiper-button-next {
      position: none;
      right: 0;
      display: flex;
      justify-content: right;
    }
  }

  @media (min-width: 1320px) {
    .swiper-slide > div {
      flex-direction: row;
    }
  }
`;

export const StyledCard = styled.li`
background-color: #F4D443;
  padding: 0 10px;
  height: 100%;
  width: 100%;
  max-width: 400px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 5%;

  & > figure {
    padding: 4px;
    width: 100%;
    height: 60%;
    object-fit: cover;
    background-color: #FEFEFE;
  }

  & > figure > img {
    width: 100%;
    height: 250px;
    max-height: 100%;
  }

  & > span {
    color: #000;
    font-size: 17px;
    font-weight: 600;
    line-height: 30px;
    padding-bottom: 0;
    text-shadow: 2px 2px 2px #fff;
  }
`;

export const StyledButton = styled.a`
  color: yellow;
  position: absolute;
`;
