import { Routes, Route } from "react-router-dom";
import { Home } from "../pages/home";
import MontagensIndustriais from "../pages/MontagensIndustriais";
import ProjetosDeEngenharia from "../pages/ProjetosDeEngenharia";
import DiluidoresEPreparadosDeCloroEmPastilha from "../pages/DiluidoresEPreparadosDeCloroEmPastilha";
import PecasEspeciaisParaDiversasAplicacoes from "../pages/PecasEspeciaisParaDiversasAplicacoes";
import PreparadoresDePolimeroEmEmulsao from "../pages/PreparadoresDePolimeroEmEmulsao";
import ResfriadoresDeAmostraParaAguaDeCaldeira from "../pages/ResfriadoresDeAmostraParaAguaDeCaldeira";
import TratamentoEControleDeAguaParaTorreDeResfriamento from "../pages/TratamentoEControleDeAguaParaTorreDeResfriamento";
import TratamentoEControleParaAguaDeCaldeira from "../pages/TratamentoEControleParaAguaDeCaldeira";
import { Valores } from "../pages/valores";

export const RoutesComponents = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/montagensIndustriais" element={<MontagensIndustriais />} />
      <Route path="/projetosDeEngenharia" element={<ProjetosDeEngenharia />} />
      <Route path="/valores" element={<Valores />} />
      <Route
        path="/diluidoresEPreparadosDeCloroEmPastilha"
        element={<DiluidoresEPreparadosDeCloroEmPastilha />}
      />
      <Route
        path="/pecasEspeciaisParaDiversasAplicacoes"
        element={<PecasEspeciaisParaDiversasAplicacoes />}
      />
      <Route
        path="/preparadoresDePolimeroEmEmulsao"
        element={<PreparadoresDePolimeroEmEmulsao />}
      />
      <Route
        path="/resfriadoresDeAmostraParaAguaDeCaldeira"
        element={<ResfriadoresDeAmostraParaAguaDeCaldeira />}
      />
      <Route
        path="/tratamentoEControleDeAguaParaTorreDeResfriamento"
        element={<TratamentoEControleDeAguaParaTorreDeResfriamento />}
      />
      <Route
        path="/tratamentoEControleParaAguaDeCaldeira"
        element={<TratamentoEControleParaAguaDeCaldeira />}
      />
    </Routes>
  );
};
