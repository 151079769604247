import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { CarouselComponent } from "../../components/Carousel";
import { Container } from "../../container";
import {
  StyledBannerHome,
  StyledSectionAboutUs,
  StyledSectionCards,
  StyledSectionContato,
  StyledSectionParceiros,
  StyledSectionProdutos,
} from "./style";
import { BsCheck2Circle } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
import { IoBookmarkSharp } from "react-icons/io5";

import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";

interface iFormData {
  nome: string;
  email: string;
  telefone: string;
  mensagem: string;
}

export const Home = () => {
  AOS.init({
    duration: 1000,
  });

  const [envioSucesso, setEnvioSucesso] = useState(false);
  const [erroEnvio, setErroEnvio] = useState<string | null>(null);

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const email = (formData.get("email") ?? "").toString();

    // Validação de campos obrigatórios
    const camposObrigatorios = ["nome", "email"];
    camposObrigatorios.forEach((campo) => {
      if (!formData.get(campo)) {
        setErroEnvio(`O campo "${campo}" é obrigatório.`);
        return;
      }
    });

    if (!email.match(emailRegex)) {
      setErroEnvio("Por favor, insira um endereço de e-mail válido.");
      return;
    }

    fetch("https://formsubmit.co/imetal@imetalmontagens.com.br", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        const contentType = response.headers.get("Content-Type");

        if (contentType && contentType.includes("application/json")) {
          return response.json();
        } else {
          throw new Error("Resposta não contém JSON.");
        }
      })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.status === 200 || data.status === 302) {
          setEnvioSucesso(true);
          setErroEnvio(null);
          toast.success("Enviado com sucesso!");
        } else {
          setErroEnvio(
            "Ocorreu um erro ao enviar o formulário. Tente novamente mais tarde."
          );
          setEnvioSucesso(false);
          toast.error(
            "Ocorreu um erro ao enviar o formulário. Tente novamente mais tarde."
          );
        }
      })
      .catch((error) => {
        console.error(error);
        setErroEnvio(
          "Ocorreu um erro ao enviar o formulário. Tente novamente mais tarde."
        );
        setEnvioSucesso(true);
        toast.success("Enviado com sucesso!");
      })
      .finally(() => {
        form.reset();
      });
  };

  return (
    <>
      <StyledBannerHome>
        <Container>
          <div>
            <h1>IMETAL</h1>
            <h2>Equipamentos e Montagens Industriais</h2>
            <p>
              Estamos empenhados e preparados para oferecer aos nossos clientes
              a melhor solução, sempre focados na otimização do tempo e no
              melhor custo-benefício para os seus projetos.{" "}
            </p>
            <div>
              <a href="#contact">CONTRATE AGORA</a>
              <a href="#aboutUs">SOBRE NÓS</a>
            </div>
          </div>
        </Container>
      </StyledBannerHome>
      <StyledSectionAboutUs id="aboutUs">
        <Container>
          <figure>
            <img
              src={
                "https://res.cloudinary.com/dut76ufs0/image/upload/v1703622264/IMG_2969_awxbth-min_dv98pl-min_oicgli.jpg"
              }
              alt="Imagem de sobre nos nova"
              loading="lazy"
            />
          </figure>
          <li>
            <h2 data-aos="fade-down">Sobre a empresa</h2>
            <p data-aos="fade-up">
              No ano de 2012, os sócios <span>Adelson</span> e{" "}
              <span>Felipe</span> fundaram a IMETAL. Desde então, a empresa tem
              experimentado um crescimento constante ao longo dos anos. Ao
              expandir nosso quadro de funcionários de maneira significativa,
              conseguimos proporcionar aos nossos clientes uma mão de obra cada
              vez mais especializada. Além disso, demos um passo importante ao
              adquirir um prédio próprio, equipado com a infraestrutura
              necessária para a fabricação dos nossos equipamentos.{" "}
            </p>
            <p data-aos="fade-down">
              Com ferramentas de alta qualidade e matéria-prima selecionada,
              garantimos o funcionamento e o desempenho excelentes dos nossos
              produtos. Todas essas iniciativas de investimento culminaram em
              nosso reconhecimento no mercado. Hoje, orgulhosamente atendemos às
              demandas dos principais players do nosso segmento, conquistando
              uma posição de destaque e confiança no setor.
            </p>
          </li>
        </Container>
      </StyledSectionAboutUs>
      <StyledSectionCards>
        <div>
          <div>
            <BsCheck2Circle />
          </div>
          <h2 data-aos="fade-up">Missão</h2>
          <p data-aos="fade-right">
            Nossa missão é ser líderes na fabricação de produtos de alta
            qualidade, oferecendo soluções inovadoras que atendam às
            necessidades de nossos clientes, impulsionando o progresso da
            indústria global.
          </p>
        </div>

        <div>
          <div>
            {" "}
            <IoBookmarkSharp />{" "}
          </div>
          <h2 data-aos="fade-up">Valores</h2>
          <p data-aos="fade-up">
            Na IMETAL, acreditamos que os valores são a base sólida sobre a qual
            construímos nosso sucesso. Cada um dos nossos valores é um pilar que
            nos guia em nossas ações diárias e influencia a maneira como nos
            relacionamos com nossos clientes, parceiros e equipe. Convidamos
            você a conhecer mais sobre nossos valores e como eles moldam a
            essência da nossa empresa.
          </p>
          <Link to="/valores" style={{ color: "#fff", textDecoration: "none" }}>
            Saiba Mais
          </Link>
        </div>

        <div>
          <div>
            <FaEye />
          </div>
          <h2 data-aos="fade-left">Visão</h2>
          <p data-aos="fade-up">
            Nossa visão é ser a escolha preferida das indústrias, oferecendo uma
            ampla gama de equipamentos industriais de alta qualidade que
            impulsionem a produtividade e a competitividade.
          </p>
        </div>
      </StyledSectionCards>
      <StyledSectionProdutos>
        <div>
          <h2>Confira nossos produtos!</h2>
        </div>
        <CarouselComponent />
      </StyledSectionProdutos>
      <StyledSectionParceiros id="clients">
        <h2>Clientes</h2>
        <div>
          <div data-aos="fade-right">
            <img
              src="https://res.cloudinary.com/dut76ufs0/image/upload/v1703630265/IMetal/sauer-min_oaj1ch.png"
              alt="Imagem da Sauer"
              loading="lazy"
            />
          </div>
          <div data-aos="fade-right">
            <img
              src="https://res.cloudinary.com/dut76ufs0/image/upload/v1693015786/IMetal/nalco-logo-tagline-svg-removebg-preview_dijqx3.png"
              alt="Imagem denalcowater"
              loading="lazy"
            />
          </div>
          <div data-aos="fade-right">
            <img
              src="https://res.cloudinary.com/dut76ufs0/image/upload/v1693179240/IMetal/kurita-removebg-preview_r4uevy.png"
              alt="Imagem de kurita"
              loading="lazy"
            />
          </div>
          <div data-aos="fade-right">
            <img
              src="https://res.cloudinary.com/dut76ufs0/image/upload/v1692898441/IMetal/walchem_wn6u8v.png"
              alt="Imagem de walchem"
              loading="lazy"
            />
          </div>
          <div data-aos="fade-right">
            <img
              src="https://res.cloudinary.com/dut76ufs0/image/upload/v1692898441/IMetal/VEOLIA_doigmr.png"
              alt="Imagem de VEOLIA"
              loading="lazy"
            />
          </div>
          <div data-aos="fade-right">
            <img
              src="https://res.cloudinary.com/dut76ufs0/image/upload/v1692898440/IMetal/ChemTreat_rwq617.png"
              alt="Imagem de ChemTreat"
              loading="lazy"
            />
          </div>
          <div data-aos="fade-right">
            <img
              src="https://res.cloudinary.com/dut76ufs0/image/upload/v1692899961/IMetal/hydro-removebg_uey8yc.png"
              alt="Imagem de hydro"
              loading="lazy"
            />
          </div>
          <div data-aos="fade-right">
            <img
              src="https://res.cloudinary.com/dut76ufs0/image/upload/v1692898440/IMetal/Buckman_glfpt9.png"
              alt="Imagem de Buckman"
              loading="lazy"
            />
          </div>
          <div data-aos="fade-right">
            <img
              src="https://res.cloudinary.com/dut76ufs0/image/upload/v1692899961/IMetal/biogreen-removebg_tnlqmz.png"
              alt="Imagem de biogreen"
              loading="lazy"
            />
          </div>
          <div data-aos="fade-right">
            <img
              src="https://res.cloudinary.com/dut76ufs0/image/upload/v1692899961/IMetal/dorf-removebg_gngjha.png"
              alt="Imagem de dorf"
              loading="lazy"
            />
          </div>
        </div>
      </StyledSectionParceiros>
      <StyledSectionContato id="contact">
        <div>
          <h2>Fale Conosco</h2>
          <form
            action="https://formsubmit.co/imetal@imetalmontagens.com.br"
            method="post"
            onSubmit={handleFormSubmit}
          >
            <input type="hidden" name="_template" value="table" />
            <input type="hidden" name="_captcha" value="false" />
            {/* <input type="hidden" name="_next" value="/home" /> */}

            <input type="text" name="nome" placeholder="Nome" required />
            <input type="email" name="email" placeholder="Email" required />
            <input type="tel" name="telefone" placeholder="Telefone" required />
            <textarea
              name="mensagem"
              placeholder="Mensagem"
              required
            ></textarea>
            <button type="submit">Enviar</button>
          </form>
        </div>
      </StyledSectionContato>
      <ToastContainer />
    </>
  );
};
