import styled from 'styled-components';

export const StyledFooterComponent = styled.footer`
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  gap: 20px;
  padding: 20px;
  width: 100%;

  & > iframe {
    width: 100%;
    max-width: 700px;
  }

  & > p {
    color: white;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  & > button {
    color: white;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: transparent;
  }

  p > a {
    color: #fff;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  div > div {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    padding: 5px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  & > div > div:hover {
    cursor: pointer;

    svg {
      color: yellow;
      transition: 0.7s;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
