import { useState, useEffect } from "react";
import { StyledProjetosDeEngenharia } from "./style";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Modal, ModalCloseButton } from "../../Modal";

const images = [
  {
    id: 1,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701905438/IMetal/Projetos%20de%20Engenharia/Imagens%20Duplas/Dimensionamento_de_Layout_de_Instala%C3%A7%C3%A3o_com_Lista_de_Componentes_1.1_meuecp.jpg",
    alt: "Dimensionamento de Layout de Instalação com Lista de Componentes",
    description:
      "Dimensionamento de Layout de Instalação com Lista de Componentes",
    group: "Componentes",
  },
  {
    id: 2,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701905438/IMetal/Projetos%20de%20Engenharia/Imagens%20Duplas/Dimensionamento_de_Layout_de_Instala%C3%A7%C3%A3o_com_Lista_de_Componentes_1.0_r2efod.jpg",
    alt: "Dimensionamento de Layout de Instalação com Lista de Componentes",
    description:
      "Dimensionamento de Layout de Instalação com Lista de Componentes",
    group: "Componentes",
  },
  {
    id: 3,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701905437/IMetal/Projetos%20de%20Engenharia/Imagens%20Duplas/Dimensionamento_de_Layout_de_Instala%C3%A7%C3%A3o_com_Lista_de_Componentes_2.0_oumvu8.jpg",
    alt: "Dimensionamento de Layout de Instalação com Lista de Componentes",
    description:
      "Dimensionamento de Layout de Instalação com Lista de Componentes",
    group: "Componentes2",
  },
  {
    id: 4,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701905437/IMetal/Projetos%20de%20Engenharia/Imagens%20Duplas/Dimensionamento_de_Layout_de_Instala%C3%A7%C3%A3o_com_Lista_de_Componentes_2.1_q1iqft.jpg",
    alt: "Dimensionamento de Layout de Instalação com Lista de Componentes",
    description:
      "Dimensionamento de Layout de Instalação com Lista de Componentes",
    group: "Componentes2",
  },
  {
    id: 5,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701905437/IMetal/Projetos%20de%20Engenharia/Imagens%20Duplas/Dimensionamento_de_Skid_com_Lista_de_Componentes_e_Conex%C3%B5es_3.0_qrguya.jpg",
    alt: "Dimensionamento de Skid com Lista de Componentes e Conexões",
    description: "Dimensionamento de Skid com Lista de Componentes e Conexões",
    group: "Componentes3",
  },
  {
    id: 6,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701905433/IMetal/Projetos%20de%20Engenharia/Imagens%20Duplas/Dimensionamento_de_Skid_com_Lista_de_Componentes_e_Conex%C3%B5es_3.1_kbkgn6.jpg",
    alt: "Dimensionamento de Skid com Lista de Componentes e Conexões",
    description: "Dimensionamento de Skid com Lista de Componentes e Conexões",
    group: "Componentes3",
  },
  {
    id: 7,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701905426/IMetal/Projetos%20de%20Engenharia/Imagens%20Duplas/Dimensionamento_de_Skid_com_Lista_de_Componentes_e_Conex%C3%B5es_4.0_xyayvb.jpg",
    alt: "Dimensionamento de Skid com Lista de Componentes e Conexões",
    description: "Dimensionamento de Skid com Lista de Componentes e Conexões",
    group: "Componentes4",
  },
  {
    id: 8,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701905426/IMetal/Projetos%20de%20Engenharia/Imagens%20Duplas/Dimensionamento_de_Skid_com_Lista_de_Componentes_e_Conex%C3%B5es_4.1_madc8i.jpg",
    alt: "Dimensionamento de Skid com Lista de Componentes e Conexões",
    description: "Dimensionamento de Skid com Lista de Componentes e Conexões",
    group: "Componentes4",
  },
  {
    id: 9,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701905426/IMetal/Projetos%20de%20Engenharia/Imagens%20Duplas/Dimensionamento_de_Skid_com_Lista_de_Componentes_e_Conex%C3%B5es_4.2_zqtjff.jpg",
    alt: "Dimensionamento de Skid com Lista de Componentes e Conexões",
    description: "Dimensionamento de Skid com Lista de Componentes e Conexões",
    group: "Componentes4",
  },
  {
    id: 10,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701905426/IMetal/Projetos%20de%20Engenharia/Imagens%20Duplas/Dimensionamento_de_Skids_com_Lista_de_Componentes_e_Conex%C3%B5es_5.1_fixtmk.jpg",
    alt: "Dimensionamento de Skids com Lista de Componentes e Conexões",
    description: "Dimensionamento de Skids com Lista de Componentes e Conexões",
    group: "Componentes5",
  },
  {
    id: 11,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701905426/IMetal/Projetos%20de%20Engenharia/Imagens%20Duplas/Dimensionamento_de_Skids_com_Lista_de_Componentes_e_Conex%C3%B5es_5.0_li3i2j.jpg",
    alt: "Dimensionamento de Skids com Lista de Componentes e Conexões",
    description: "Dimensionamento de Skids com Lista de Componentes e Conexões",
    group: "Componentes5",
  },
  {
    id: 12,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701905426/IMetal/Projetos%20de%20Engenharia/Imagens%20Duplas/Dimensionamento_de_Skids_com_Lista_de_Componentes_e_Conex%C3%B5es_6.1_ggrcsk.jpg",
    alt: "Dimensionamento de Skids com Lista de Componentes e Conexões",
    description: "Dimensionamento de Skids com Lista de Componentes e Conexões",
    group: "Componentes6",
  },
  {
    id: 13,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701905426/IMetal/Projetos%20de%20Engenharia/Imagens%20Duplas/Dimensionamento_de_Skids_com_Lista_de_Componentes_e_Conex%C3%B5es_6.0_z4q17p.jpg",
    alt: "Dimensionamento de Skids com Lista de Componentes e Conexões",
    description: "Dimensionamento de Skids com Lista de Componentes e Conexões",
    group: "Componentes6",
  },
  {
    id: 14,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701292480/IMetal/Projetos%20de%20Engenharia/imagens%20unicas/Fluxograma_de_Aplica%C3%A7%C3%A3o_-_Dosagem_de_Cloro_Tam._Reduzido_ukd1i8.jpg",
    alt: "Fluxograma de Aplicação - Dosagem de Cloro",
    description: "Fluxograma de Aplicação - Dosagem de Cloro",
    group: "ind1",
  },
  {
    id: 15,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701292487/IMetal/Projetos%20de%20Engenharia/imagens%20unicas/Fluxograma-_Preparador_de_Pol%C3%ADmero_Autom%C3%A1tico-_Explicativo_Tam._Reduzido_mbnl1q.jpg",
    alt: "Fluxograma- Preparador de Polímero Automático- Explicativo",
    description: "Fluxograma- Preparador de Polímero Automático- Explicativo",
    group: "ind2",
  },
  {
    id: 16,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701292480/IMetal/Projetos%20de%20Engenharia/imagens%20unicas/Fluxograma_-_Skid_de_Dosagem_Tam._Reduzido_rnwnjw.jpg",
    alt: "Fluxograma - Skid de Dosagem",
    description: "Fluxograma - Skid de Dosagem",
    group: "ind3",
  },
  {
    id: 17,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701292481/IMetal/Projetos%20de%20Engenharia/imagens%20unicas/Dimensionamento_de_Layout_de_Instala%C3%A7ao_Tam._Reduzido_rfpsfs.jpg",
    alt: "Dimensionamento de Layout de Instalação",
    description: "Dimensionamento de Layout de Instalação",
    group: "ind4",
  },
  {
    id: 18,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701292481/IMetal/Projetos%20de%20Engenharia/imagens%20unicas/Fluxograma_-_Skid_de_Dosagem_-_%C3%81cido_Clor%C3%ADdrico_e_Soda_Tam._Reduzido_fmkajj.jpg",
    alt: "Fluxograma - Skid de Dosagem - Ácido Clorídrico e Soda",
    description: "Fluxograma - Skid de Dosagem - Ácido Clorídrico e Soda",
    group: "ind5",
  },
  {
    id: 19,
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701292480/IMetal/Projetos%20de%20Engenharia/imagens%20unicas/Fluxograma_-_Skid_de_Dosagem_-_Hipoclor%C3%ADto_Tam._Reduzido_ams8jn.jpg",
    alt: "Fluxograma - Skid de Dosagem - Hipocloríto",
    description: "Fluxograma - Skid de Dosagem - Hipocloríto",
    group: "ind6",
  },
];

const idToGroup: Record<number, string> = {};
images.forEach((image) => {
  idToGroup[image.id] = image.group;
});

const ProjetosDeEngenharia = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(1);

  const openModal = (id: number) => {
    setSelectedImageId(id);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const openPrevImage = () => {
    const currentGroup = idToGroup[selectedImageId];
    const groupImages = images.filter((image) => image.group === currentGroup);
    const currentIndex = groupImages.findIndex(
      (image) => image.id === selectedImageId
    );

    if (currentIndex !== -1) {
      const prevIndex =
        (currentIndex - 1 + groupImages.length) % groupImages.length;
      setSelectedImageId(groupImages[prevIndex].id);
    }
  };

  const openNextImage = () => {
    const currentGroup = idToGroup[selectedImageId];
    const groupImages = images.filter((image) => image.group === currentGroup);
    const currentIndex = groupImages.findIndex(
      (image) => image.id === selectedImageId
    );

    if (currentIndex !== -1) {
      const nextIndex = (currentIndex + 1) % groupImages.length;
      setSelectedImageId(groupImages[nextIndex].id);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        openPrevImage();
      } else if (event.key === "ArrowRight") {
        openNextImage();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <StyledProjetosDeEngenharia>
      <div className="containers">
        <h1 className="resp-mobile"> Projetos de Engenharia</h1>
        <div className="image-containers">
          {images
            .filter(
              (image) =>
                image.id === 1 ||
                image.id === 3 ||
                image.id === 5 ||
                image.id === 7 ||
                image.id === 10 ||
                image.id === 12 ||
                image.id === 14 ||
                image.id === 15 ||
                image.id === 16 ||
                image.id === 17 ||
                image.id === 18 ||
                image.id === 19
            )
            .map((image) => (
              <div
                className="pair"
                key={image.id}
                onClick={() => openModal(image.id)}
              >
                <div
                  className="image"
                  onContextMenu={(e: React.MouseEvent) => e.preventDefault()}
                >
                  <img src={image.src} alt={image.alt} loading="lazy" />
                </div>
                <div className="description">
                  <span>{image.description}</span>
                </div>
              </div>
            ))}
        </div>
      </div>

      {modalIsOpen && (
        <Modal onClick={handleModalClick}>
          <ModalCloseButton onClick={closeModal}>&times;</ModalCloseButton>
          <div>
            <button onClick={openPrevImage}>
              {" "}
              <AiOutlineArrowLeft />{" "}
            </button>
            <img
              key={selectedImageId}
              src={images.find((image) => image.id === selectedImageId)!.src}
              alt={images.find((image) => image.id === selectedImageId)!.alt}
            />
            <button onClick={openNextImage}>
              {" "}
              <AiOutlineArrowRight />{" "}
            </button>
          </div>
        </Modal>
      )}
    </StyledProjetosDeEngenharia>
  );
};

export default ProjetosDeEngenharia;
