import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, A11y } from "swiper";
import { StyledCard, StyledDiv } from "./style";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Link } from "react-router-dom";

export const CarouselComponent = () => {
  const settings: SwiperProps = {
    navigation: true,
    pagination: {
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 100,
      },
    },
  };

  return (
    <StyledDiv>
      <Swiper
        pagination={true}
        navigation={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        slidesPerView={1}
        modules={[Navigation, Pagination, A11y, Autoplay]}
        {...settings}
      >
        <SwiperSlide>
          <div>
            <StyledCard>
              <figure>
                <img
                  src="https://res.cloudinary.com/dut76ufs0/image/upload/v1701452511/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_para_Instala%C3%A7%C3%A3o_em_Parede_para_An%C3%A1lise_de_pH_Orp_Condutividade_e_Tubula%C3%A7%C3%A3o_PVC_SCH80_1.1_hjefqv.jpg"
                  alt="Skid para Instalação em Parede para Análise de pH, Orp, Condutividade e Tubulação PVC SCH80"
                />
              </figure>
              <span>
                Tratamento e controle de água para torre de Resfriamento
              </span>
            </StyledCard>

            <StyledCard>
              <figure>
                <img
                  src="https://res.cloudinary.com/dut76ufs0/image/upload/v1701452502/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_todo_em_PP_para_An%C3%A1lise_de_pH_Orp_Condutividade_Corros%C3%A3o_e_Alojamento_para_4_Bombas_Dosadoras_1.2_s6mhe0.jpg"
                  alt="Skid todo em PP para Análise de pH, Orp, Condutividade, Corrosão e Alojamento para 4 Bombas Dosadoras"
                />
              </figure>
              <span>
                Tratamento e controle de água para torre de Resfriamento
              </span>
            </StyledCard>

            <StyledCard>
              <figure>
                <img
                  src="https://res.cloudinary.com/dut76ufs0/image/upload/v1701452513/IMetal/Tratamento%20E%20Controle%20De%20%C3%81gua%20Para%20Torre%20De%20Resfriamento/Skid_de_Dosagem_em_PP_-_Customizado_-_Tubula%C3%A7%C3%A3o_PVC_SCH80_1.0_zafoo5.jpg"
                  alt="Skid de Dosagem em PP - Customizado - Tubulação PVC SCH80"
                />
              </figure>
              <span>
                Tratamento e controle de água para torre de Resfriamento
              </span>
            </StyledCard>
          </div>

          <Link
            to="/tratamentoEControleDeAguaParaTorreDeResfriamento"
            aria-label="Ver detalhes sobre tratamento E Controle De Agua Para Torre De Resfriamento"
          >
            {" "}
            <button>Veja Mais</button>
          </Link>
        </SwiperSlide>

        <SwiperSlide>
          <div>
            <StyledCard>
              <figure>
                <img
                  src="https://res.cloudinary.com/dut76ufs0/image/upload/v1701802620/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/SKIDEM_1_nkjrd5.jpg"
                  alt="Skid Aberto em Inox para Dosagem de Produto em Linha de Alta Pressão, Tubulação em Inox OD"
                />
              </figure>
              <span>Tratamento e controle para água de Caldeira</span>
            </StyledCard>

            <StyledCard>
              <figure>
                <img
                  src="https://res.cloudinary.com/dut76ufs0/image/upload/v1701802621/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_todo_em_PP_para_Alojamento_de_4_Bombas_An%C3%A1lise_pH_Orp_Condutividade_Acionamento_Local_e_Remoto_e_Sistema_de_Prote%C3%A7%C3%A3o_Contra_Temperatura_e_Resfriamento_de_Amostra_1.1_h5idn5.jpg"
                  alt="Skid todo em PP para Alojamento de 4 Bombas, Análise pH, Orp, Condutividade, Acionamento Local e Remoto e Sistema de Proteção Contra Temperatura e Resfriamento de Amostra"
                />
              </figure>
              <span>Tratamento e controle para água de Caldeira</span>
            </StyledCard>

            <StyledCard>
              <figure>
                <img
                  src="https://res.cloudinary.com/dut76ufs0/image/upload/v1701802622/IMetal/Tratamento%20E%20Controle%20Para%20%C3%81gua%20De%20Caldeira/Skid_Aberto_em_Inox_para_Dosagem_de_Produto_em_Linha_de_Alta_Press%C3%A3o_Tubula%C3%A7%C3%A3o_em_Inox_OD_1.0_ssivci.jpg"
                  alt="Skid Aberto em Inox para Dosagem de Produto em Linha de Alta Pressão, Tubulação em Inox OD"
                />
              </figure>
              <span>Tratamento e controle para água de Caldeira</span>
            </StyledCard>
          </div>

          <Link
            to="/tratamentoEControleParaAguaDeCaldeira"
            aria-label="Ver detalhes sobre Tratamento e Controle de Água de Caldeira"
          >
            {" "}
            <button>Veja Mais</button>
          </Link>
        </SwiperSlide>

        <SwiperSlide>
          <div>
            <StyledCard>
              <figure>
                <img
                  src="https://res.cloudinary.com/dut76ufs0/image/upload/v1701361862/IMetal/Diluidores%20e%20preparadores%20de%20cloro%20em%20pastilha/Diluidor_de_Cloro_em_Pastilha_todo_em_PP_-_Capacidade_100_Kg_1.0_ona2tt.jpg"
                  alt="Diluidor de Cloro em Pastilha todo em PP - Capacidade 100 Kg"
                />
              </figure>
              <span>Diluidores e preparadores de cloro em Pastilha</span>
            </StyledCard>

            <StyledCard>
              <figure>
                <img
                  src="https://res.cloudinary.com/dut76ufs0/image/upload/v1701361862/IMetal/Diluidores%20e%20preparadores%20de%20cloro%20em%20pastilha/Sistema_de_Dilui%C3%A7%C3%A3o_Preparo_e_Dosagem_de_Cloro_em_Pastilha_1.0_y2yhl1.jpg"
                  alt="Sistema de Diluição, Preparo e Dosagem de Cloro em Pastilha"
                />
              </figure>
              <span>Diluidores e preparadores de cloro em Pastilha</span>
            </StyledCard>

            <StyledCard>
              <figure>
                <img
                  src="https://res.cloudinary.com/dut76ufs0/image/upload/v1701361861/IMetal/Diluidores%20e%20preparadores%20de%20cloro%20em%20pastilha/Sistema_de_Diluidores_de_Cloro_em_Pastilha_em_S%C3%A9rie_todo_em_PP_1.0_tjjfus.jpg"
                  alt="Sistema de Diluidores de Cloro em Pastilha em Série todo em PP"
                />
              </figure>
              <span>Diluidores e preparadores de cloro em Pastilha</span>
            </StyledCard>
          </div>

          <Link
            to="/diluidoresEPreparadosDeCloroEmPastilha"
            aria-label="Ver detalhes sobre Diluidores E Preparados De Cloro Em Pastilha"
          >
            {" "}
            <button>Veja Mais</button>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <StyledCard>
              <figure>
                <img
                  src="https://res.cloudinary.com/dut76ufs0/image/upload/v1701289980/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_com_Estrutura_em_Inox_Fechamento_de_Aluminio_com_Policarbonato_e_Tubula%C3%A7%C3%A3o_em_Inox_Sch10_1.0_us5kom.jpg"
                  alt="Skid com Estrutura em Inox, Fechamento de Aluminio com  Policarbonato e Tubulação em Inox Sch10"
                />
              </figure>
              <span>Preparadores de Polímero em Emulsão</span>
            </StyledCard>

            <StyledCard>
              <figure>
                <img
                  src="https://res.cloudinary.com/dut76ufs0/image/upload/v1701289977/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Skid_com_Estrutura_em_Inox_Fechamento_de_Aluminio_com_Policarbonato_e_Tubula%C3%A7%C3%A3o_em_PVC_SCH80_2.0_z1eizs.jpg"
                  alt="Skid com Estrutura em Inox, Fechamento de Aluminio com  Policarbonato e Tubulação em PVC SCH80"
                />
              </figure>
              <span>Preparadores de Polímero em Emulsão</span>
            </StyledCard>

            <StyledCard>
              <figure>
                <img
                  src="https://res.cloudinary.com/dut76ufs0/image/upload/v1701289967/IMetal/Preparadores%20de%20Polimero%20em%20Emuls%C3%A3o/Sistema_de_Dosagem_de_Pol%C3%ADmero_1.0_wymfaq.jpg"
                  alt="Sistema de Dosagem de Polímero"
                />
              </figure>
              <span>Preparadores de Polímero em Emulsão</span>
            </StyledCard>
          </div>

          <Link
            to="/preparadoresDePolimeroEmEmulsao"
            role="link"
            aria-label="Ver detalhes sobre Preparadores De Polimero Em Emulsão"
          >
            {" "}
            <button>Veja Mais</button>
          </Link>
        </SwiperSlide>

        <SwiperSlide>
          <div>
            <StyledCard>
              <figure>
                <img
                  src="https://res.cloudinary.com/dut76ufs0/image/upload/v1694374797/IMetal/Resfriadores%20amostra%20de%20%C3%A1gua%20para%20caldeira/Bico_Injetor_de_Produto_com_Sistema_de_Resfriamento_todo_em_Inox_mnkpwv.jpg"
                  alt="Bico Injetor de Produto com Sistema de Resfriamento todo em Inox"
                />
              </figure>
              <span>Resfriadores de amostra para água de Caldeira</span>
            </StyledCard>

            <StyledCard>
              <figure>
                <img
                  src="https://res.cloudinary.com/dut76ufs0/image/upload/v1694374795/IMetal/Resfriadores%20amostra%20de%20%C3%A1gua%20para%20caldeira/M%C3%B3dulo_de_Resfriamento_Quadriplo_em_A%C3%A7o_Inox_com_Sistema_de_Prote%C3%A7%C3%A3o_Shi..._dff76j.jpg"
                  alt="Módulo de Resfriamento Quadriplo em Aço Inox com Sistema de Proteção Shield"
                />
              </figure>
              <span>Resfriadores de amostra para água de Caldeira</span>
            </StyledCard>

            <StyledCard>
              <figure>
                <img
                  src="https://res.cloudinary.com/dut76ufs0/image/upload/v1694374795/IMetal/Resfriadores%20amostra%20de%20%C3%A1gua%20para%20caldeira/Resfriador_de_Amostra_em_A%C3%A7o_Inox_oblnty.jpg"
                  alt="Resfriador de Amostra em Aço Inox"
                />
              </figure>
              <span>Resfriadores de amostra para água de Caldeira</span>
            </StyledCard>
          </div>

          <Link
            to="/resfriadoresDeAmostraParaAguaDeCaldeira"
            role="link"
            aria-label="Ver detalhes sobre Resfriadores De Amostra Para Agua De Caldeira"
          >
            {" "}
            <button>Veja Mais</button>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <StyledCard>
              <figure>
                <img
                  src="https://res.cloudinary.com/dut76ufs0/image/upload/v1694298306/IMetal/Pe%C3%A7as%20especiais%20para%20diversas%20aplica%C3%A7%C3%B5es/Bico_Injetor_de_Produto_-_C-PVC_swupzc.png"
                  alt="Bico Injetor de Produto - C-PVC"
                />
              </figure>
              <span>Peças especiais para diversas Aplicações</span>
            </StyledCard>

            <StyledCard>
              <figure>
                <img
                  src="https://res.cloudinary.com/dut76ufs0/image/upload/v1694298303/IMetal/Pe%C3%A7as%20especiais%20para%20diversas%20aplica%C3%A7%C3%B5es/Header_de_Distribui%C3%A7%C3%A3o_em_A%C3%A7o_Inox_oqudtd.jpg"
                  alt="Header de Distribuição em Aço Inox"
                />
              </figure>
              <span>Peças especiais para diversas Aplicações</span>
            </StyledCard>

            <StyledCard>
              <figure>
                <img
                  src="https://res.cloudinary.com/dut76ufs0/image/upload/v1694296116/IMetal/Pe%C3%A7as%20especiais%20para%20diversas%20aplica%C3%A7%C3%B5es/Sistema_de_Filltragem_-_Estrutura_em_A%C3%A7o_Inox_e_Tubula%C3%A7%C3%B5es_PVC-U_Sch80_ngromu.jpg"
                  alt="Sistema de Filltragem - Estrutura em Aço Inox e Tubulações PVC-U Sch80"
                />
              </figure>
              <span>Peças especiais para diversas Aplicações</span>
            </StyledCard>
          </div>
          <Link
            to="/pecasEspeciaisParaDiversasAplicacoes"
            role="link"
            aria-label="Detalhes sobre Peças Especiais Para Diversas Aplicações"
          >
            {" "}
            <button>Veja Mais</button>
          </Link>
        </SwiperSlide>
      </Swiper>
    </StyledDiv>
  );
};
