import styled from 'styled-components';

export const StyledPageValores = styled.div`
  margin-top: 110px;
  width: 100%;
  max-height: 750px;
  background: url('https://res.cloudinary.com/dut76ufs0/image/upload/v1691007816/IMetal/contact_bg_syon14.jpg');
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0;

  & > h2 {
    font-size: 40px;
    color : #000000;
  }

  .swiper {
    padding: 25px 10px;
    width: 95%;
    height: 500px;
    position: relative;
    margin-top: 20px;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    gap: 20px;
    height: 100%;
  }

  .swiper-slide-prev,
  .swiper-slide-next {
    background-color: transparent;
  }

  .swiper-pagination-bullet-active {
    background-color: #f3cf2d;
  }

  @media (min-width: 720px) {
    .swiper {
      width: 70%;
    }
  }
`;

export const StyleHeaderComponents = styled.header``;

export const StyeledCard = styled.div`
  min-width: 290px;
  max-width: 300px;
  height: 400px;
  background-color: white;
  border-radius: 20px;
  border: 2px solid #f3cf2d;
  box-shadow: 3px -1px 17px 0px #f3cf2d;
  -webkit-box-shadow: 3px -1px 17px 0px #f3cf2d;
  -moz-box-shadow: 3px -1px 17px 0px #f3cf2d;
  padding: 10px;
  text-align: center;

  & > div {
    height: 60px;
    width: 60px;
    padding: 15px;
    background-color: #f3cf2d;
    border-radius: 50%;
    margin: 20px auto;
  }

  & > div > svg {
    width: 100%;
    height: 100%;
    color: white;
  }

  & > h2 {
    font-size: 30px;
    font-weight: bold;
    color: #000000;
    margin-top: 20px;
  }

  & > p {
    font-size: 16px;
    color: grey;
    margin-top: 20px;
    text-align: center;
  }
`;
