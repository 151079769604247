import React, { useState } from "react";
import { FaArrowUp } from "react-icons/fa";
import { StyledScrollToTopButton } from "./style";

export const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    console.log(isVisible);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisibility);

  return (
    <StyledScrollToTopButton
      onClick={scrollToTop}
      style={{ display: isVisible ? "block" : "none" }}
    >
      <FaArrowUp />
    </StyledScrollToTopButton>
  );
};