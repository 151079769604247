import styled, { keyframes } from 'styled-components';

const shakeAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
`;

export const StyledWhatsapp = styled.figure`
  width: 50px;
  height: 50px;
  border: none;
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: #25d366;
  border-radius: 50%;

  animation: ${shakeAnimation} 1s ease-in-out infinite;
  animation-delay: 5s;

  > button {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 5px;
  }
  > button > img {
    width: 100%;
    height: 100%;
  }
`;
