import React, { useState, useEffect } from "react";
import { StyledMontagensIndustriais } from "./style";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Modal, ModalCloseButton } from "../../Modal";

const images = [
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1696031646/IMetal/Montagens%20Industriais/Interliga%C3%A7%C3%A3o_de_Tanques_-_Tubula%C3%A7%C3%B5es_em_PVC_uapyfu.jpg",
    alt: "Interligação de Tanques - Tubulações em PVC",
    description: "Interligação de Tanques - Tubulações em PVC",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1701451437/IMetal/Montagens%20Industriais/Instala%C3%A7%C3%A3o_de_Skid_Tanques_e_Tubula%C3%A7%C3%B5es_para_Dosagem_2_y3wti8.jpg",
    alt: "Instalação de Skid, Tanques e Tubulações para Dosagem",
    description: "Instalação de Skid, Tanques e Tubulações para Dosagem",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1696031646/IMetal/Montagens%20Industriais/Instala%C3%A7%C3%A3o_de_Plataforma_de_Acesso_do_Tanque_qpdhsx.jpg",
    alt: "Instalação de Plataforma de Acesso do Tanque",
    description: "Instalação de Plataforma de Acesso do Tanque",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1696031646/IMetal/Montagens%20Industriais/Instala%C3%A7%C3%A3o_de_Sistema_de_Dosagem_de_Cloro_mrwuuv.jpg",
    alt: "Instalação de Sistema de Dosagem de Cloro",
    description: "Instalação de Sistema de Dosagem de Cloro",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1696031646/IMetal/Montagens%20Industriais/Instala%C3%A7%C3%A3o_de_Skid_Tanques_e_Tubula%C3%A7%C3%B5es_para_Dosagem_asmchw.jpg",
    alt: "Instalação de Skid, Tanques e Tubulações para Dosagem",
    description: "Instalação de Skid, Tanques e Tubulações para Dosagem",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1696031646/IMetal/Montagens%20Industriais/Instala%C3%A7%C3%A3o_de_Sistema_de_Dosagem_kvys58.jpg",
    alt: "Instalação de Sistema de Dosagem",
    description: "Instalação de Sistema de Dosagem",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1696031646/IMetal/Montagens%20Industriais/Escada_com_Plataforma_de_Acesso_em_A%C3%A7o_Inox_vkfaey.jpg",
    alt: "Escada com Plataforma de Acesso em Aço Inox",
    description: "Escada com Plataforma de Acesso em Aço Inox",
  },
  {
    src: "https://res.cloudinary.com/dut76ufs0/image/upload/v1696031646/IMetal/Montagens%20Industriais/Estrutura_em_A%C3%A7o_Carbono_para_Agitador_d40fwp.jpg",
    alt: "Estrutura em Aço Carbono para Agitador.",
    description: "Estrutura em Aço Carbono para Agitador",
  },
];

const MontagensIndustriais = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const openModal = (index: number) => {
    setSelectedImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const openPrevImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const openNextImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        openPrevImage();
      } else if (event.key === "ArrowRight") {
        openNextImage();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <StyledMontagensIndustriais>
      <div className="containers">
        <h1 className="resp-mobile">Montagens Industriais</h1>
        <div className="image-containers">
          {images.map((image, index) => (
            <div className="pair" key={index} onClick={() => openModal(index)}>
              <div
                className="image"
                onContextMenu={(e: React.MouseEvent) => e.preventDefault()}
              >
                <img src={image.src} alt={image.alt} loading="lazy" />
              </div>
              <div className="description">
                <span>{image.description}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {modalIsOpen && (
        <Modal onClick={handleModalClick}>
          <ModalCloseButton onClick={closeModal}>&times;</ModalCloseButton>
          <div>
            <button onClick={openPrevImage}>
              {" "}
              <AiOutlineArrowLeft />{" "}
            </button>
            <img
              key={selectedImageIndex}
              src={images[selectedImageIndex].src}
              alt={images[selectedImageIndex].alt}
            />
            <button onClick={openNextImage}>
              {" "}
              <AiOutlineArrowRight />{" "}
            </button>
          </div>
        </Modal>
      )}
    </StyledMontagensIndustriais>
  );
};

export default MontagensIndustriais;
